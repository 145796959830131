import { createStore } from 'vuex'

import CompanyStore from './storeModules/CompanyStore.js';
import LicenseStore from './storeModules/LicenseStore.js';
import RoleStore from './storeModules/RoleStore.js';
import EnvironmentStore from './storeModules/EnvironmentStore.js';
import appSettingsStore from './storeModules/AppSettingsStore.js';
import userStore from './storeModules/UserStore.js';
import userSettingsStore from './storeModules/UserSettingsStore.js';

////---------------new
import materialstore from './storeModules/MaterialStore.js';


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    CompanyStore,
    LicenseStore,
    RoleStore,
    EnvironmentStore,
    appSettingsStore,
    userStore,
    userSettingsStore,

    ////---------------new
    materialstore
  }
})
