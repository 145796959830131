<template>
  <div class="modal" @keydown.esc="closeModal()">
    <div class="row">
      <SystemIcons :type="'userIcon'" class="title-icon"/>
      <h1 v-if="createdUserData == null"> <span v-if="modalMode == 'add'"> Add new user </span> <span v-else> Update {{cmptDisplayUser.givenName}}, {{cmptDisplayUser.surname}} </span>  </h1>

      <!-- <div v-if="createdUserData == null">
        <img src="Testlogo.png" alt="testlogo" style="width:100px">
        <input type="file" id="image" name="image" accept="image/png, image/jpeg">
      </div> -->
    </div>

    <div v-if="createdUserData == null" class="row header-row">
      <p class="dropdown-label">Company:</p>
      <div v-if="modalMode == 'add' && this.$store.getters.getAllCompanys.length > 1" class="row">
        <select :disabled="this.modalMode != 'add'" v-model="cmptDisplayUser.companyId" id="companyName" @change="onUserCompanySelected">
          <option disabled value="">Please select one</option>
          <option
            v-for="(item, index) in cmptCompanies"
            :key = index
            :value="item.id"
          >{{ item.name }}</option>
        </select>
        <p class="info">This user will only be added to the selected company</p>
      </div>
      <p v-else> {{cmptDisplayUser.companyName}} </p>
    </div>

    <div v-if="createdUserData == null" class="modal-content">
      <div class="row">
        <div class="name-block">
          <label for="givenName">Given name</label>
          <input v-model="cmptDisplayUser.givenName" type="text" name="Enter given name" placeholder="Enter given name" id="givenName">
        </div>
        <div class="name-block">
          <label for="displayName">Surname</label>
          <input v-model="cmptDisplayUser.surname" type="text" name="Enter surname" placeholder="Enter surname" id="surname">
        </div>
      </div>

      <label for="displayName">Display name</label>
      <input v-model="cmptDisplayUser.displayName" type="text" name="Enter display name" placeholder="Enter display name" id="displayName">

      <label for="eMail">Email</label>
      <input v-model="cmptDisplayUser.eMail" type="eMail" name="Enter eMail" placeholder="Enter eMail" id="eMail" :disabled="modalMode != 'add'" @change="warningText =null">

      <section class="dropdown-section">
        <p class="title">Role</p>
        <select v-model="cmptDisplayUser.role" id="role" @change="warningText =null">
          <option disabled value="">Please select one</option>
          <option
            v-for="(item, index) in cmptUserRoles"
            :key = index
            :value="item.id"
          >{{ item.name }}</option>
          <option disabled="disabled">----------</option>
          <option value="-1" style="color:grey">None</option>
        </select>
      </section>
      <!-- <p>Role: {{cmptDisplayUser.role}}</p> -->
    </div>

    <div v-else class="confirmation-block">

      <div class="text-block">
        <h1>User sucessfully created!</h1>
        <p>Login: <span class="bold">{{cmptCreatedUser.eMail}}</span></p>
        <p>Initial password: <span class="bold">{{cmptInitialPwd}}</span> </p>
        <p class="info">The User will be prompted to change password on first log in</p>

        <p class="warning">The PWD will no longer be shown after closing this dialog</p>
      </div>

    </div>

    <div class="row">
      <button v-if="createdUserData == null" @click.prevent="closeModal()" class="btn-standard btn-gray">Cancel</button>
      <div class="spread"></div>
      <span v-if="warningText" style="color:red">{{ warningText }}</span>
      <div class="spread"></div>
      <button @click.prevent="submit()" class="btn-standard btn-green">{{cmptButtonText}}</button>
    </div>
  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'
import { UserViewModel } from '@/customCode/AdministrationViewModels/AdminViewModels.js'


export default {
  name: 'ModalViewCreateUpdateUser',

  components: {
    SystemIcons
  },

  props: {
    data: null,
    modalMode: String
  },

  data () {
    return {
      isSubmitted: false,
      createableUser: new UserViewModel(),
      updateableUser: null,
      warningText: null,
      createdUserData: null
    }
  },

  computed: {
  cmptDisplayUser(){
    // if in edit mode
    if(this.modalMode != 'add') {
      if(this.updateableUser == null) {
        this.updateableUser = { ...this.data } //return clone (else changes = localy persistant)
      }
      console.log("cmptDisplayUser mode 'edit' this.updateableUser", this.updateableUser);
      return this.updateableUser
    }
    //if in create mode
      console.log("cmptDisplayUser mode 'add'", this.createableUser);
      return this.createableUser
    },
    cmptLogoUri(){

      // return cmptDisplayUser.logo
      return "Testlogo.png"
    },
    cmptCompanies(){
      return this.$store.getters.getAllCompanys
    },
    cmptUserRoles(){
      return this.$store.getters.getUserRoles.filter( r => r.creatorCompanyId == this.cmptDisplayUser.companyId)
    },

    cmptButtonText(){
      return (this.createdUserData == null) ? "Save Changes" : "Close"
    },

    cmptCreatedUser(){
      if(this.createdUserData != null){
        return this.createdUserData.userDto || {}
      }
      return {}
    },

    cmptInitialPwd(){
      if(this.createdUserData != null){
        return this.createdUserData.initialPassword || ""
      }
      return ""
    },
  },

  methods: {
    onUserCompanySelected(){
      this.warningText = null
      let company = this.cmptCompanies.find(c => c.id == this.cmptDisplayUser.companyId)
      this.cmptDisplayUser.companyName = (company!= null)? company.name : null
      console.log("doadaer cmptUserRoles ", this.cmptUserRoles);
      if(this.cmptUserRoles.length == 0)
        this.warningText = "Create a role for the selected company first"
    },
    async submit () {
      if(this.createdUserData != null){
        this.closeModal()
        return
      }

      this.isSubmitted = true

      // console.log(" displayUser-companyId ", this.cmptDisplayUser.companyId )

      // //TEMP for test
      if(this.modalMode == 'add'){
      // this.cmptDisplayUser.displayName = "John the Boss Wayne"
      // this.cmptDisplayUser.givenName = "John"
      // this.cmptDisplayUser.surname = "Wayne"
      // this.cmptDisplayUser.eMail = "John.Wayne@Home.com"
      // this.cmptDisplayUser.role = 2
      }

      if(!this.checkValidity()){
        this.isSubmitted = false
        return
      }

      if(this.modalMode == 'add'){
        this.createdUserData = await this.createUser()

        console.log("CREATE User got createdUserData: ", this.createdUserData);
        // this.warningText = "Got User " + newUser.displayName
        return
      }
      else {
        this.updateUser()
      }
      // console.log('ModalViewCreateUpdateUser - submit - ', 'isSubmitted: ', this.isSubmitted);
      this.closeModal()
    },

    closeModal () {
      this.isSubmitted = false
      this.$emit('closingModal')
    },

    checkValidity(){
      if((this.cmptDisplayUser.companyId == null) || (this.cmptDisplayUser.companyId.length == 0)){
        this.warningText = "Set Company"
        return false
      }
      if((this.cmptDisplayUser.eMail == null) || (this.cmptDisplayUser.eMail.length == 0)){
        this.warningText = "Set eMail"
        return false
      }
      if(!this.isValidEmail(this.cmptDisplayUser.eMail)){
        this.warningText = "Incorrect eMail format"
        return false
      }

      if((this.cmptDisplayUser.displayName == null) || (this.cmptDisplayUser.displayName.length == 0)){
        this.warningText = "Set display name"
        return false
      }

      if((this.cmptDisplayUser.displayName == null) || (this.cmptDisplayUser.displayName.length == 0)){
        this.warningText = "Set display name"
        return false
      }

      if((this.cmptDisplayUser.displayName == null) || (this.cmptDisplayUser.displayName.length == 0)){
        this.warningText = "Set display name"
        return false
      }

      if(!this.isValidRole(this.cmptDisplayUser.role))
      {
        this.warningText = "Select a role for the user"
        return false
      }

      return true
    },

    isValidEmail(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },

    isValidRole(roleId){
      if((roleId == null) || (roleId.length == 0) || (roleId == "-1"))
        return false

      return true
    },

    async createUser(){
      let createdUserData = await this.$store.dispatch('createUser', this.cmptDisplayUser)
      // console.log("createUser result ", result);
      return createdUserData
    },

    updateUser(){
      this.$store.dispatch('updateUser', this.cmptDisplayUser)
    },
  },

  created() {},
  mounted() {
    if(this.modalMode == 'add') {
      if (this.$store.getters.getAllCompanys.length == 1) {
        let company = this.cmptCompanies.find(c => c.id == this.$store.getters.getCurrentUser.companyId)
        this.createableUser.companyId = (company!= null)? company.id : null
        this.createableUser.companyName = (company!= null)? company.name : null
      }
    }
  },
  beforeUnmount() {}
}
</script>

<style scoped lang="scss">
.modal-content {

  @include flexbox;
  @include flex-direction(column);

  input {
    background-color: var(--bg-primary);
    margin-bottom: 1rem;
  }

  .name-block {
    min-width: calc(50% - .5rem);
    @include flexbox;
    @include flex-direction(column);

    &:first-of-type {
      margin-right: .5rem;
    }
    &:last-of-type {
      margin-left: .5rem;
    }
  }

  .title {
    font-weight: 700;
  }

  .dropdown-section {
    margin-bottom: 7rem;

    select {
      min-width: 50%;
    }
  }
}

.confirmation-block {
  @include flexbox;
  // @include flex-direction(column);
  @include align-items(center);
  @include flex-just(center);

  .text-block {
    max-width: 75%;
    padding: 2rem 0 4rem 0;

    .bold {
      color: var(--accent-primary);
    }

    h1 {
      margin-bottom: 1rem;
    }

    .info {
      margin: 0 !important;
    }

    .warning {
      background-color: var(--warning-background);
      color: var(--accent-tertiary);
      border: 1px solid var(--accent-tertiary);
      padding: 1rem;
      margin: 4rem 0 1rem 0;
      border-radius: .5rem;
    }
  }
}
</style>