<template>
  <div class="modal" @keydown.esc="closeModal()">

    <div v-if="modalMode.action == 'add'">
      <label for="licenseType">License type</label>
      <select v-model="licenseType" id="licenseType">
        <option v-if="allowEditorLicensing()" value="EDITOR">Editor</option>
        <option v-if="allowPublisherLicensing()" value="PUBLISHER">Publisher</option>
        <option v-if="allowU3MLicensing()" value="U3M">U3M</option>
      </select>
    </div>

    <div class="row">
      <SystemIcons :type="'license'" class="title-icon"/>
      <h1> <span v-if="modalMode.action == 'add'"> Add new license </span> <span v-else> Update {{cmptDisplayLicense.name}} </span> </h1>
    </div>

    <div class="row header-row">
      <p class="dropdown-label">Company:</p>
      <div v-if="modalMode.action == 'add' && this.$store.getters.getAllCompanys.length > 1" class="row">
        <select :disabled="this.modalMode.action != 'add'" v-model="cmptDisplayLicense.creatorCompanyId" id="companyName" @change="onCompanySelected">
          <option disabled value="">Please select one</option>
          <option
            v-for="(item, index) in cmptCompanies"
            :key = index
            :value="item.id"
          >{{ item.name }}</option>
        </select>
        <p class="info">This role will only be added to the selected company</p>
      </div>
      <p v-else> {{cmptDisplayLicense.creatorCompany}} </p>

      <p style="color:blue">- Type: {{cmptDisplayLicenseType}}</p>
    </div>


    <!-- General fields for all license types -->
    <div class="modal-content">
      <!-- <div> -->
        <label for="name">Name</label>
        <input v-model="cmptDisplayLicense.name" type="text" id="name" placeholder="Enter name" @change="warningText =null" >

        <label for="description">Description</label>
        <input v-model="cmptDisplayLicense.description" type="text" id="description" placeholder="Enter description" >

        <label for="startDate">Start Date</label>
        <input v-model="cmptDisplayLicense.startDate" type="datetime" id="startDate" placeholder="Enter startDate" >

        <label for="endDate">End Date</label>
        <input v-model="cmptDisplayLicense.endDate" type="datetime" id="endDate" placeholder="Enter endDate" >

        <label for="maxUsers">maxUsers</label>
        <input v-model="cmptDisplayLicense.maxUsers" type="number" id="maxUsers" placeholder="Enter maxUsers" >

        <label for="storageGB">storageGB</label>
        <input v-model="cmptDisplayLicense.storageGB" type="number" id="storageGB" placeholder="Enter storageGB" >

        <label for="viewsPerMonth">viewsPerMonth</label>
        <input v-model="cmptDisplayLicense.viewsPerMonth" type="number" id="viewsPerMonth" placeholder="Enter viewsPerMonth" >

        <label for="enableUserAdmin">enableUserAdmin</label>
        <input v-model="cmptDisplayLicense.enableUserAdmin" type="checkbox" id="enableUserAdmin" placeholder="Enter enableUserAdmin" >

        <label for="enableEditorLicensing">enableEditorLicensing</label>
        <input v-model="cmptDisplayLicense.enableEditorLicensing" type="checkbox" id="enableEditorLicensing" placeholder="Enter enableEditorLicensing" >

        <label for="enablePublisherLicensing">enablePublisherLicensing</label>
        <input v-model="cmptDisplayLicense.enablePublisherLicensing" type="checkbox" id="enablePublisherLicensing" placeholder="Enter enablePublisherLicensing" >

        <label for="enableU3MLicensing">enableU3MLicensing</label>
        <input v-model="cmptDisplayLicense.enableU3MLicensing" type="checkbox" id="enableU3MLicensing" placeholder="Enter enableU3MLicensing" >

        <!-- Only display 'Created by, when editing, else not yet created' -->
        <!-- <label v-if="modalMode.action != 'add'" for="creatorName">Created by</label>
        <input v-if="modalMode.action != 'add'" v-model="cmptDisplayLicense.creatorName" type="text" id="creatorName" placeholder="Enter creatorName" > -->
      <!-- </div> -->

      <!-- Editor license fields -->

      <div v-if="cmptDisplayLicense.category == 'EDITOR'">
        <!-- <pre> {{ cmptDisplayLicense }} </pre> -->

        <label for="manageProjects">manageProjects</label>
        <input v-model="cmptDisplayLicense.manageProjects" type="checkbox" id="manageProjects" placeholder="Enter manageProjects" >
        <label for="editMaterials">editMaterials</label>
        <input v-model="cmptDisplayLicense.editMaterials" type="checkbox" id="editMaterials" placeholder="Enter editMaterials" >
        <label for="enableDeployment">enableDeployment</label>
        <input v-model="cmptDisplayLicense.enableDeployment" type="checkbox" id="enableDeployment" placeholder="Enter enableDeployment" >

      </div>

      <div v-else-if="cmptDisplayLicense.category == 'PUBLISHER'">
        <!-- <pre> {{ cmptDisplayLicense }} </pre> -->

        <label for="displayWatermark">displayWatermark</label>
        <input v-model="cmptDisplayLicense.displayWatermark" type="checkbox" id="displayWatermark" placeholder="Enter displayWatermark" >
        <label for="display3DInfo">display3DInfo</label>
        <input v-model="cmptDisplayLicense.display3DInfo" type="checkbox" id="display3DInfo" placeholder="Enter display3DInfo" >
        <label for="createThumbnails">createThumbnails</label>
        <input v-model="cmptDisplayLicense.createThumbnails" type="checkbox" id="createThumbnails" placeholder="Enter createThumbnails" >

      </div>

      <div v-else-if="cmptDisplayLicense.category == 'U3M'">
        <!-- <pre> {{ cmptDisplayLicense }} </pre> -->

        <label for="downloadMaterial">downloadMaterial</label>
        <input v-model="cmptDisplayLicense.downloadMaterial" type="checkbox" id="downloadMaterial" placeholder="Enter downloadMaterial" >
        <label for="uploadMaterial">uploadMaterial</label>
        <input v-model="cmptDisplayLicense.uploadMaterial" type="checkbox" id="uploadMaterial" placeholder="Enter uploadMaterial" >

      </div>


        <!-- Readonly info fields -->
       <div v-if="modalMode.action != 'add'">
        <!-- Only display 'Created by, when editing, else not yet created' -->
        <label for="creatorName">Created by</label>
        <input v-model="cmptDisplayLicense.creatorName" type="text" id="creatorName" placeholder="Enter creatorName" readonly>

        <label for="createdOn">Created on</label>
        <input :value="parseDate(cmptDisplayLicense.createdOn)" type="text" id="createdOn" placeholder="Creation Date" readonly >

        <label for="creatorName">Company</label>
        <input v-model="cmptDisplayLicense.creatorCompany" type="text" id="creatorCompany" placeholder="Company" readonly>
      </div>

    </div>


    <div class="row">
      <button @click.prevent="closeModal()" class="btn-standard btn-gray">Cancel</button>
      <div class="spread"></div>
      <span v-if="warningText" style="color:red">{{ warningText }}</span>
      <div class="spread"></div>
      <button :disabled="cmptDisableLicenseUpdateing" @click.prevent="submit()" class="btn-standard btn-green">Save Changes</button>
    </div>
  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'
import { EditorLicense, PublisherLicense,  U3MViewerLicense} from '@/customCode/AdministrationViewModels/LicenseViewModels.js'
import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'
import { parseDateMixins } from '@/mixins/ParseDateMixins.js'

export default {
  name: 'Modal__CreateUpdateLicense',

  components: {
    SystemIcons
  },

  mixins:[displayAuthMixins, parseDateMixins],

  props: {
    data: null,
    modalMode: Object, // {action: action, displayLicenseType: displayLicenseType}
  },

  data () {
    return {
      licenseType: "EDITOR",
      isSubmitted: false,
      warningText: null,
      editorlicense: new EditorLicense(),
      publisherLicense: new PublisherLicense(),
      u3mViewerLicense: new U3MViewerLicense(),
      updateLicenseObj: null,
      // mydate:  new Date().toISOString().substr(0, 10) // 05/09/2019
    }
  },

  computed: {
    cmptCompanies(){
      return this.$store.getters.getAllCompanys
    },
    cmptDisplayLicenseType(){
       switch(this.cmptDisplayLicense.category){
        case "EDITOR":
          return "Editor License"
        case "PUBLISHER":
          return "Publisher License"
        case "U3M":
        return "Fabrix License"
        default:
          return ""
      }

    },

    cmptDisplayLicense(){
      // console.log("da Data ...........", this.data, "dispLic ")

      // if in edit mode
      if(this.modalMode.action != 'add') {
        if(this.updateLicenseObj == null) {
          this.updateLicenseObj = { ...this.data } //return clone (else changes = localy persistant)
        }
        return this.updateLicenseObj
      }

      switch(this.licenseType){
        case "EDITOR":
          return this.editorlicense
        case "PUBLISHER":
          return this.publisherLicense
        case "U3M":
        return this.u3mViewerLicense
      }
    },

    cmptDisableLicenseUpdateing(){
      if(this.$store.getters.getCurrentUser.isCLAdmin) return false
      return ((this.cmptDisplayLicense.category == "EDITOR" && !this.allowEditorLicensing ()) ||
       (this.cmptDisplayLicense.category == "PUBLISHER" && !this.allowPublisherLicensing ()) ||
       (this.cmptDisplayLicense.category == "U3M" && !this.allowU3MLicensing ()))
    }
  },

  methods: {
    onCompanySelected(){
      this.warningText = null
      let company = this.cmptCompanies.find(c => c.id == this.cmptDisplayLicense.creatorCompanyId)
      this.cmptDisplayLicense.creatorCompany = (company!= null)? company.name : null
    },
    submit () {
      if(!this.checkValidity()){
        this.isSubmitted = false
        return
      }

      this.isSubmitted = true
      if(this.modalMode.action == 'add'){
        this.createLicense()
      }
      else{
        this.updateLicense()
      }
      //   Do something.....

      console.log('Modal__CreateUpdateLicense - submit - ', 'isSubmitted: ', this.isSubmitted);
      this.closeModal()
    },

    closeModal () {
      this.isSubmitted = false
      this.$emit('closingModal')
    },

    checkValidity(){
      if((this.cmptDisplayLicense.creatorCompanyId == null) || (this.cmptDisplayLicense.creatorCompanyId.length == 0)){
        this.warningText = "Set Company"
        return false
      }

      if((this.cmptDisplayLicense.name == null) || (this.cmptDisplayLicense.name.length == 0)){
        this.warningText = "Set Name"
        return false
      }
      return true
    },

    createLicense(){
      this.$store.dispatch('createLicense', this.cmptDisplayLicense)
    },

    updateLicense(){
      // console.log("updateLicense licenseType ", this.cmptDisplayLicense);
      this.$store.dispatch('updateLicense', this.cmptDisplayLicense)
    }

  },

  created() {},
  mounted() {
    // console.log("da Data ..........MOUNTED .", this.data, "dispLic ")
    if(this.modalMode.action == 'add') {
      if (this.$store.getters.getAllCompanys.length == 1) {
        let company = this.cmptCompanies.find(c => c.id == this.$store.getters.getCurrentUser.companyId)
        this.cmptDisplayLicense.creatorCompanyId = (company!= null)? company.id : null
        this.cmptDisplayLicense.creatorCompany = (company!= null)? company.name : null
      }
    }
  },
  beforeUnmount() {}
}
</script>

<style scoped lang="scss">
.modal-content {
  @include flexbox;
  @include flex-direction(column);

  input {
    background-color: var(--bg-primary);
    margin-bottom: 1rem;
  }
}

//  // LBA: added here .... start
  label {
  display: block;
  width: 12em;
  float: left; /* makes the element act like inline block */
  clear: left; /* clears any left floats so before so this should start on new line */
  }

  input {
    // width: 10em;
    float: left;
  }
</style>