<template>
  <div class="dropdown-container margin-r" :class="{'dropdon-open' : dropdownOpen}" @mouseleave="dropdownOpen = false">
    <button @click="dropdownOpen = !dropdownOpen" class="dropdown">
      <SystemIcons v-if="data.current != 'All'" :type="'category' + data.current + ''" class="category-icon"/>
      <p v-else class="title margin-r not-mobile"> {{data.selector}} </p>
      <p class="category-title not-mobile"> {{data.current}} </p>
      <SystemIcons :type="'dropdownChevronDown'" class="dropdown-chevron"/>
    </button>
    <ul v-if="dropdownOpen" class="dropdon-content">

      <li @click="$emit('onSelection', 'All')" :class="{'active' : data.current == 'All' }" class="no-icon"> All </li>
      <li v-for="(item, index) in data.list" :key="index" @click="$emit('onSelection', item.itemName)" :class="{'active' : data.current == item.itemName }" >
        <SystemIcons v-if="data.showIcon" :type="'category' + item.itemName + ''" />
        {{item.itemName}}
      </li>

    </ul>
  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'

export default {
  name: 'DropdownElement',

  components: {
    SystemIcons
  },

  props: {
    data: Object,
    selectedCategory: String
  },

  data () {
    return {
      dropdownOpen: false
    }
  },

  computed: {}
}
</script>

<style scoped lang="scss">
$input-height: 3.5rem;

.dropdown-container {
  @include flex(auto, 0, 0);
  position: relative;
  background-color: var(--bg-secondary);
  border-radius: $input-height / 2;
  height: min-content;

  min-width: 13rem;

  @include respond-to('small') {
    min-width: 5rem;

    .not-mobile {
      display: none;
    }
    // .dropdown {

    //   .dropdon-content {
    //     background-color: salmon !important;
    //     min-width: 13rem;
    //   }
    // }
  }


  .dropdown {
    cursor: pointer;
    position: relative;
    border: 1px solid #eaedf0;
    background: none;
    border: none;
    height: $input-height;
    width: 100%;
    padding: .5rem 1.5rem;
    font-size: 1.25rem;
    text-align: left;

    @include flexbox;
    @include align-items(center);

    .category-icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: .75rem;
    }

    .title {
      @include flex(auto, 0, 0);

      &.margin-r {
        margin-right: .5rem;
      }
    }
    .category-title {
      @include flex(auto, 1, 1);
    }

    .dropdown-chevron {
      width: .75rem;
      height: auto;
      margin-left: 1.5rem;
      transition: transform .2s ease-out;

      @include respond-to('small') {
        margin-left: .5rem;
      }
    }
  }

  &.dropdon-open {
    border-radius: $input-height / 2 $input-height / 2 0 0;
    box-shadow: 0 .5rem .5rem .25rem rgba(0, 0, 0, 0.075);

    .dropdown {
      .dropdown-chevron {
        transform: scaleY(-1);
      }
    }
  }

  .dropdon-content {
    z-index: 10;
    position: absolute;
    width: 100%;
    background-color: var(--bg-secondary);
    border-radius: 0 0 $input-height / 2 $input-height / 2;
    border-top: 1px solid var(--bg-primary);
    box-shadow: 0 .5rem .5rem .25rem rgba(0, 0, 0, 0.075);
    padding: .5rem 0 1.5rem 0;

    @include respond-to('small') {
      // background-color: salmon !important;
      min-width: 13rem;
      right: 0;
      border-radius: $input-height / 2 0 $input-height / 2 $input-height / 2;
      padding: 1.5rem 0 1.5rem 0;
    }

    li {
      cursor: pointer;
      padding: .5rem 1.5rem;
      @include flexbox;
      @include align-items(center);

      &:hover {
        background-color: var(--bg-primary);
      }

      &.active {
        // background-color: var(--bg-primary);
        background-color: var(--accent-primary);
        color: var(--bg-secondary);
        svg {
          fill: var(--bg-secondary);
        }
      }

      &.no-icon {
        padding-left: 3.5rem;
      }

      svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: .75rem;
      }
    }
  }
}
</style>