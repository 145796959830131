

export const parseDateMixins = {

  methods: {
    parseDate (dateString, shortDate = false) {
      if((dateString == null) ||(dateString.length == 0))
        return ""
      if(shortDate)
        return new Date(`${dateString}Z`).toLocaleString('de-DE', { dateStyle: "short" });

      return new Date(`${dateString}Z`).toLocaleString('de-DE');
    },

  },

  computed: {
    // cmptActiveCameraSet(){
    //   return this.$store.getters.getActiveCameraSet || {}
    // },
  },
  mounted() {

  },
}