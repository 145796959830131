import * as Msal from '@azure/msal-browser'
export const authConfig = {
  // clientid: 'your-client-id-also-called-applicationId',
  // authority: 'https://login.microsoftonline.com/tfp/<tenant url>/<name-for-sign-up-and-sign-in-user-flow>'

  auth: { //Coniclabs Testsystem

    clientId: 'd5da04a8-163b-47c8-9f0b-4b94b043aa32', // PolyteIdentityPortal
    authority: 'https://coniclabspolyteuser.b2clogin.com/coniclabspolyteuser.onmicrosoft.com/B2C_1_SuSi',

    knownAuthorities: ['coniclabspolyteuser.b2clogin.com'],
    response_type: 'token', // response_type: ' code',
    // redirectUri: 'http://localhost:8085/company/products'
    // redirectUri: 'http://localhost:8090/'
    // redirectUri: 'https://fabrix.netlify.app'
    redirectUri: 'https://fabrix.vizoo3d.com/'
    // redirectUri:  process.env.VUE_APP_REDIRECT_URI,

    //navigateToLoginRequestUrl: true
  },


  cache: {
    cacheLocation: 'localStorage',
    // storeAuthStateInCookie: true
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case Msal.LogLevel.Error:
            console.error(message)
            return
          case Msal.LogLevel.Info:
            console.info(message)
            return
          case Msal.LogLevel.Verbose:
            console.debug(message)
            return
          case Msal.LogLevel.Warning:
            console.warn(message)
        }
      }
    }
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  // //Coniclabs  ****
  scopes: ['https://coniclabspolyteuser.onmicrosoft.com/962df7ee-4df6-4c0a-8372-f28014deca3e/access_as_user'], //PolytePublisherApi

  //Lanres Testsystem *****
  // scopes: ['https://lanresorg.onmicrosoft.com/7a9be43e-ee64-4b13-bce6-834a4df853e3/access_as_user'], //PublicserSErverApi
}

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
export const accessTokenRequest = {

  //Coniclabs  Testsystem ****
  scopes: [ 'https://coniclabspolyteuser.onmicrosoft.com/962df7ee-4df6-4c0a-8372-f28014deca3e/access_as_user', "offline_access"],//PolytePublisherApi

  //Lanres Testsystem *****
  // scopes: [ 'https://lanresorg.onmicrosoft.com/7a9be43e-ee64-4b13-bce6-834a4df853e3/access_as_user'],//PublicserSErverApi
}

export const graphConfig = {
  // graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/",
  graphMeEndpoint: 'https://graph.microsoft.com/beta/me/'
}
