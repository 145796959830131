import axios from 'axios'

import { UserRoleViewModel } from '@/customCode/AdministrationViewModels/AdminViewModels.js'


const state = {
  roleList: [
    // new UserRoleViewModel("Admin", "Super User with extended rights"),
    // new UserRoleViewModel("Artist", "Regular user with lesser rights"),
    // new UserRoleViewModel("Guest User", "A guest user")
  ],
  activeRoleId: null
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// MUTATIONS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mutations = {
  SET_ROLE_LIST (state, payload) {
    console.log('Set Azure roleList collection -> ', payload)
    state.roleList = payload
  },

  ADD_ROLE (state, payload) {
    console.log('Add Role payload ', payload.newRole)
    // state.roleCollection.roles.unshift(payload); //
    state.roleList.push(payload.newRole) // Considering sorting by list?
    // console.log('Add Role state.roleLis ', state.roleList)
  },

  UPDATE_ROLE (state, payload) {
    console.log('Update Role payload ', payload.updateRole)

    var index = state.roleList.findIndex(l => l.id == payload.updateRole.id);
    // if (index !== -1) {
      state.roleList[index] = payload.updateRole
  },
  // SET_ACTIVE_ROLE_ID (state, payload) {
  //   state.activeRoleId = payload
  // }
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// ACTIONS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const actions = {
  // setActiveRoleID ({ commit }, activeRoleId) { // Gui function
  //   commit('SET_ACTIVE_ROLE_ID', activeRoleId)
  // },

  /// //////////////////////////////////////
  // fetchCurrentUserRole ({ commit }) {
  //   return axios.get('/UserRole')
  //     .then((response) => {
  //     // commit('SET_TOKEN', response.data.token);
  //       console.log('*fetchAllUserRoles: found roles on server.., ', response.data)
  //       commit('SET_ROLE_LIST', response.data)
  //       // resolve(response)
  //       return response
  //     })
  // },

  fetchAllUserRoles ({ commit }) {
    return axios.get('/UserRole')
      .then((response) => {
      // commit('SET_TOKEN', response.data.token);
        console.log('*fetchAllUserRoles: found roles on server.., ', response.data)
        commit('SET_ROLE_LIST', response.data)
        // resolve(response)
        return response
      })
  },


  async createRole (context, newRole) { // gets {role: this.fields} //Old

    // console.log('Creating role---------', newRole)
    let azureResponse = await axios.post('/UserRole', newRole)
    let azureRole = azureResponse.data

    // console.log('*RoleStore: got azure Role... ', azureRole)
    context.commit('ADD_ROLE', { newRole: azureRole })
    return azureRole;
  },

  async updateRole (context, updateRole) { // gets {role: this.fields} //Old

    console.log('Updating role---------', updateRole)


    let azureResponse = await axios.patch('/UserRole', updateRole)
    let azureRole = azureResponse.data

    console.log('*RoleStore: got azure Role... ', azureRole)
    context.commit('UPDATE_ROLE', { updateRole: azureRole })
    return azureRole
  }
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// GETTERS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getters = {
  getUserRoles: state => state.roleList,
  // getActiveRoleID: state => state.activeRoleId,
  // getActiveRole: state => state.roleList.find(p => p.id == state.activeRoleId) || {}
}

const roleModule = {
  state,
  mutations,
  actions,
  getters
}

export default roleModule
