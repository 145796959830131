<template>
  <div class="modal" @keydown.esc="closeModal()">
    <div class="row header-row">
      <SystemIcons :type="'userIcon'" class="title-icon"/>
      <h1> <span> Info </span>  </h1>
    </div>

    <div  class="modal-content">
      <!-- <textarea v-if="this.data != null">{{ this.data.message }}</textarea> -->
      <textarea v-model="message"></textarea>
    </div>

    <div class="row">
      <button @click.prevent="closeModal()" class="btn-standard btn-gray">Ok</button>
      <div class="spread"></div>
    </div>
  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'


export default {
  name: 'Modal__NoLicenseFoundInfo',

  components: {
    SystemIcons
  },

  props: {
    data: null,
  },

  data () {
    return {
      message : ""
    }
  },

  computed: { },

  methods: {

    closeModal () {
      this.$emit('closingModal')
    },

  },

  created() {},
  mounted() {
    this.message = this.data.message
  },
  beforeUnmount() {}
}
</script>

<style scoped lang="scss">
.modal-content {
  @include flexbox;
  @include flex-direction(column);

  input {
    background-color: var(--bg-primary);
    margin-bottom: 1rem;
  }
}
</style>