import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import theAuthService from './auth/msal/AuthService.js'
import axios from './axiosExt.js'
import VueAxios from 'vue-axios'
import FloatingVue from 'floating-vue'


axios.defaults.baseURL = store.getters.getActiveEnvironment.url

const app = createApp(App).use(store).use(router).use(VueAxios, axios).use(FloatingVue)
app.config.globalProperties.$AuthService = theAuthService
app.mount('#app')