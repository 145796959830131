import axios from 'axios'
import { EditorLicense, PublisherLicense,  U3MViewerLicense} from '@/customCode/AdministrationViewModels/LicenseViewModels.js'

const state = {
  ownCompanyLicenses: null,
  // {
  //   editorLicense: null,
  //   publisherLicense: null,
  //   u3MViewerLicense: null
  // },
  licenseList: []
  //  [
  //   new EditorLicense('EDI - Business', "Editor Multiuser License", "A license for businesses"),
  //   new EditorLicense('EDI - TRIAL', "Editor Trial License (30)", "Limited for 30 days"),
  //   new PublisherLicense('PUB- PRO', "Power Publishing", "A license for publisher. 4000 Views"),
  //   new U3MViewerLicense('U3M- PowerUser', "Full scope U3M License", "Enables everything"),
  //   new U3MViewerLicense('U3M -Read Only ', "U3M REad Only License", "A read only license for fabrix"),
  // ]
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// MUTATIONS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mutations = {
  SET_OWN_COMPANY_LICENSES (state, payload) {
    state.ownCompanyLicenses = payload.ownCompanyLicenses
  },
  SET_LICENSE_LIST (state, payload) {
    console.log('Set api key list -> ', payload.licenseList)
    state.licenseList = payload.licenseList
  },
  ADD_LICENSE (state, payload) {
    console.log('Add license  -> ', payload.license)
    state.licenseList.push(payload.license)
  },
  UPDATE_LICENSE (state, payload) {
    console.log('Update license -> ', payload.license)
    var index = state.licenseList.findIndex(l => l.id == payload.license.id);
    // if (index !== -1) {
      state.licenseList[index] = payload.license
    // }
  },
  DELETE_LICENSE (state, payload) {
    console.log('delete license-> ', payload)
    const idx = state.licenseList.findIndex(v => v.id == payload)
    state.licenseList.splice(idx, 1)
  }
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// ACTIONS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const actions = {

  /**
   * Fetch the License for own company
   * @param {*} param0
   * @param {*} companyId
   * @returns the user company license (immutable)
   */
   fetchOwnCompanyLicenses ({ commit }) {

    return axios.get(`CompanyLicense`)
    .then((response) => {
      console.log("*Fetching ownCompanyLicens: found .., ", response.data);
      commit('SET_OWN_COMPANY_LICENSES', { ownCompanyLicenses: response.data })
      return response.data;
    })
  },


  /**
   * Fetch all Licenses for company
   * @param {*} param0
   * @param {*} companyId
   * @returns all licenses created by company
   */
  fetchAllLicensesForCompany ({ commit }) {

    return axios.get(`Licenses`)
    .then((response) => {

      console.log("*FetchingLicenses: found .., ", response.data);

      let allLics = [...response.data.editorLicenses, ...response.data.publisherLicenses,
         ...response.data.u3MViewerLicenses]

      commit('SET_LICENSE_LIST', { licenseList: allLics })
      //resolve(response)
      return response;
    })
  },


  /**
   * Create a License for company
   * @param {*} param0
   * @param {*} companyId
   * @returns all licenses created by company
   */

  createLicense ({ commit }, createObj) {
    console.log('createLicense  -> ', createObj)

    let licensePath
    switch(createObj.category){
      case "EDITOR":
        licensePath = "EditorLicense"
        break;
      case "PUBLISHER":
        licensePath = "PublisherLicense"
        break;
      case "U3M":
        licensePath = "U3MLicense"
        break;
    }

    return axios.post(`${licensePath}`, createObj)
    .then((response) => {

      console.log("*Created license:.., ", response.data);
      commit('ADD_LICENSE', { license: response.data })
      return response;
    })
  },



  /**
   * Create a License for company
   * @param {*} param0
   * @param {*} companyId
   * @returns all licenses created by company
   */

   updateLicense ({ commit }, updateObj) {
    // console.log('updateLicense  -> ', updateObj)
    let licensePath
    switch(updateObj.category){
      case "EDITOR":
        licensePath = "EditorLicense"
        break;
      case "PUBLISHER":
        licensePath = "PublisherLicense"
        break;
      case "U3M":
        licensePath = "U3MLicense"
        break;
    }

    return axios.patch(`${licensePath}`, updateObj)
    .then((response) => {
      // console.log("*Updated license:.., ", response.data);
      commit('UPDATE_LICENSE', { license: response.data })
      return response;
    })
  },




  deleteLicense ({ commit }, lic) { // gets licenseId

    let deleteObj = { id: lic.id, category: lic.category }

    return axios.delete(`Licenses`, { data: deleteObj })
    .then((response) => {
      //commit('SET_TOKEN', response.data.token);
      console.log("*deleteLicense: common response.., ", response.data);
      commit('DELETE_LICENSE', response.data)
      //resolve(response)
      // return response.data;
      return {suceeded: true, companies: null}
    }).
    catch(function (error) {
      if (error.response && error.response.status == 409) { //conflict

        return {suceeded: false, companies: error.response.data}
      }
      //  else if (error.request) {
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   console.log(" deleteLicense ..eerror.message -", error.message);
      // }
    })
  },

  validateLogIn ({ commit }, valData) { //Gets { client: 'Publisher', company: userData.companyId }
    console.log(" validateLogIn ", valData);
      return axios.post('ValidateLogIn', valData)
      // return axios(
      // {
      //   method:'post',
      //   url:'ValidateLogIn',
      //   data: valData,
      //  })

        .then((response) => {
          return response
        })

  },

  registerLogOff ({ commit }, valData) { //Gets { client: 'Publisher', company: userData.companyId }
    if (axios.useLocalStorage == false) {
      return axios.post('RegisterLogOff', valData)
        .then((response) => {
        // commit('SET_TOKEN', response.data.token);
          console.log('*registerLogOff: response .., ', response.data)
          //commit('SET_COMPANY_LIST', response.data)
          // resolve(response)
          return response
        })
    } else {
    }
  },

  // updateLicense ({ commit }, updateObj) {  // gets {companyId: companyId, licenseId: license.id, updateField: updateField}

  //   console.log("updateLicense  -> ", updateObj.companyId, " licenseId ", updateObj.licenseId);
  //   let companyId = updateObj.companyId
  //   let licenseId = updateObj.licenseId
  //   let updateField = updateObj.updateField

  //   if(axios.useLocalStorage == false){

  //     return axios.post(`Companies/${companyId}/Licenses/${licenseId}`, updateField)
  //     .then((response) => {
  //       //commit('SET_TOKEN', response.data.token);
  //       // console.log("*FetchingLicenses: found .., ", response.data);
  //       // commit('SET_LICENSE_LIST', { licenseList: response.data })
  //       //resolve(response)
  //       return response;
  //     })
  //   }
  //   else
  //   {
  //     // console.log("FetchingAllProducts:.. from testdata ", TEST_DATA.productCollection);
  //     // commit('SET_PRODUCT_LIST', { productCollection: TEST_DATA.productCollection })
  //     commit('SET_LICENSE_LIST', { licenseList: TEST_DATA.licenseList })
  //   }
  // },

  // deleteLicense ({ commit }, versionObj) { // gets { companyId: companyId, productId: productId, licenseId: licenseId.id }

  //   let companyId = versionObj.companyId
  //   let productId = versionObj.productId
  //   let licenseId = versionObj.licenseId

  //   if(axios.useLocalStorage == false){
  //     return axios.delete(`Companies/${companyId}/Products${productId}/Licenses/${licenseId}`)
  //     .then((response) => {
  //       //commit('SET_TOKEN', response.data.token);
  //       console.log("*deleteLicense: f.., ", response.data);
  //       commit('DELETE_LICENSE', response.data)
  //       //resolve(response)
  //       return response.data;
  //     })
  //   }
  //   else
  //   {
  //     // console.log("FetchingAllProducts:.. from testdata ", TEST_DATA.productCollection);
  //     // commit('SET_PRODUCT_LIST', { productCollection: TEST_DATA.productCollection })
  //     commit('DELETE_LICENSE', licenseId)
  //   }
  // },

}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// GETTERS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getters = {
  getOwnCompanyLicenses: state => state.ownCompanyLicenses,
  getLicenseList: state => state.licenseList
  // getCompanyLicenses: state => state.licenseList,
  // getLiveProductVersionId: state => state.liveVersionId,
}

const licenseModule = {
  state,
  mutations,
  actions,
  getters
}

export default licenseModule
