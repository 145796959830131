<template>
  <div id="global-modal-container">
    <div class="modal-background" @click="closeModal()"></div>
    <component
      :is="cmptGlobalModalState.contentType"
      :modalMode="cmptGlobalModalState.modalMode"
      :data="cmptGlobalModalState.data"
      @closingModal="closeModal()"
    />

  </div>
</template>

<script>
import Modal__CreateUpdateUser from '@/modalViews/modalViews/Modal__CreateUpdateUser.vue'
import Modal__CreateUpdateCompany from '@/modalViews/modalViews/Modal__CreateUpdateCompany.vue'
import Modal__CreateUpdateLicense from '@/modalViews/modalViews/Modal__CreateUpdateLicense.vue'
import Modal__CreateUpdateRole from '@/modalViews/modalViews/Modal__CreateUpdateRole.vue'
import Modal__UploadMaterial from '@/modalViews/modalViews/Modal__UploadMaterial.vue'
import Modal__UserInfo from '@/modalViews/modalViews/Modal__UserInfo.vue'
import Modal__NoLicenseFoundInfo from '@/modalViews/modalViews/Modal__NoLicenseFoundInfo.vue'

export default {
  name: 'TheGlobalModal',

  components: {
    // Modal__CreateUpdateUser: () => import(/* webpackChunkName: "Modal__CreateUpdateUser" */ '@/modalViews/modalViews/Modal__CreateUpdateUser.vue'),
    Modal__CreateUpdateUser,
    Modal__CreateUpdateCompany,
    Modal__CreateUpdateLicense,
    Modal__CreateUpdateRole,
    Modal__UploadMaterial,
    Modal__UserInfo,
    Modal__NoLicenseFoundInfo
  },

  data () {
    return {}
  },

  computed: {
    cmptGlobalModalState () {
      return this.$store.getters.getGlobalModalState;
    }
  },

  methods: {
    closeModal () {
      this.$store.dispatch('hideGlobalModal')
    },
  },

  created() {
    // prevent body from scrolling when modal is oben
    if(this.$store.getters.getGlobalModalState) {
      document.documentElement.style.overflow = 'hidden'
      return
    }
  },
  beforeUnmount() {
    // get body scrolling back
    document.documentElement.style.overflow = 'auto'
  }
}
</script>