<template>
  <footer>
    <div class="logo-strip">
      <SystemIcons :type="'vizooLogo'" class="vizoo-logo"/>
    </div>
    <div class="top">

      <div class="left">
        <p>Vizoo GmbH</p>
        <p>Johann-Karg-Str. 30</p>
        <p>85540 Haar (Munich)</p>
        <p>Germany</p>
        
        <a
          href="mailto:info@vizoo3d.com?subject=Send from Fabrix Cloud"
          rel="noopener noreferrer"
          class="link email-link"
        > Contact by Mail </a>
      </div>

      <div class="right">
        <a
          v-for="(link, index) in footerLinks"
          :key="index"
          :href="link.link"
          class="link"
          target="_blank"
          rel="noopener noreferrer"
        > {{link.title}} </a>
      </div>
    </div>
    <div class="sozial-strip">
      <a
        v-for="(link, index) in sozialLinks"
        :key="index"
        :href="link.link"
        class="sozial-link"
        target="_blank"
        rel="noopener noreferrer"
      > <SystemIcons v-if="link.icon" :type="link.icon" class="link-icon"/> {{link.title}} </a>
    </div>
    <div class="bottom">

      <p>Legal Notice & Privacy Policy</p>
      <p>© {{cmptGetCurrentDate}} – Vizoo GmbH</p>

    </div>
  </footer>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'
export default {
  name: 'TheAppHeader',
  components: {
    SystemIcons
  },

  data () {
    return {
      footerLinks: [
        { title: 'Vizoo Homepage', link: 'https://vizoo3d.com' },
        { title: 'U3M – Unified 3D Material', link: 'https://u3m.info' },
        { title: 'Vizoo Customer Portal', link: 'https://customers.vizoo3d.com' }
      ],
      sozialLinks: [
        { title: 'Instagram', icon: 'instagramIcon', link: 'https://www.instagram.com/vizoogmbh/' },
        { title: 'Facebook', icon: 'facebookIcon', link: 'https://www.facebook.com/vizoogmbh/' },
        { title: 'LinkedIn', icon: 'linkedInIcon', link: 'https://www.linkedin.com/company/vizoo-gmbh/' }
      ]
    }
  },


  computed: {
    cmptGetCurrentDate() {
      const current = new Date();
      // return current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      return current.getFullYear()
    }
  },
}
</script>

<style scoped lang="scss">
footer {
  background-color: var(--bg-secondary);
  // min-height: 35rem;

  @include flexbox;
  @include flex-direction(column);

  .top, .bottom, .logo-strip {
    padding-left: $desktop-padding;
    padding-right: $desktop-padding;

    @include respond-to('medium') {
      padding: 2rem $tablet-padding;
    }
    @include respond-to('small') {
      padding: 1rem $mobile-padding;
    }
  }

  .logo-strip {
    padding-top: 3rem;
    padding-bottom: 1rem;

    .vizoo-logo {
      height: $header-height / 2;
      width: auto;
      fill: var(--color-primary);
      // margin: 0 1rem 2rem 0;
    }
  }

  .top {
    @include flex(auto, 1, 1);
    margin-bottom: 3rem;
    // padding-top: 3rem;
    padding-bottom: 3rem;

    @include flexbox;

    .left {
      // background-color: salmon;
      width: 50%;

      .email-link {
        margin-top: 2rem;
        margin-left: -.75rem;
      }
    }

    .right {
      width: 50%;
      text-align: right;
      height: 100%;
      margin-top: -.5rem;

      @include flexbox;
      @include flex-direction(column);
      @include align-items(flex-end);
      // @include flex-just(center);

      .link {
        margin-right: -.75rem;
      }
    }
  }

  .link {
    display: block;
    padding: .5rem .75rem;
    width: max-content;
    border-radius: .5rem;
    font-weight: 700;

    &:hover {
      background-color: var(--bg-primary);
    }
  }

  .spread {
    @include flex(auto, 1, 1);
  }

  .sozial-strip {
    padding: 1rem;
    @include flexbox;
    // @include align-items(center);
    @include flex-just(center);

    .sozial-link {
      margin: 0 2.0rem;
      padding: .5rem;
      border-radius: .5rem;
      @include flexbox;
      @include align-items(center);

      @include respond-to('small') {
        margin: 0 .5rem;
      }

      &:hover {
        background-color: var(--bg-primary);
      }

      .link-icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: .5rem;
      }
    }
  }

  .bottom {
    border-top: 1px solid var(--bg-primary);
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}
</style>