

export class CompanyViewModel {
  constructor() {
    this.id = null;
    this.name,
    this.logo,
    this.info,
    this.street,
    this.number,
    this.zipCode,
    this.city,
    this.country,
    this.phone,
    this.eMail,
    this.website,

    this.editorLicenseId,
    this.publisherLicenseId,
    this.u3MViewerLicenseId,

    this.DeploymentFolder,
    this.UserCount
  }
}

export class UserViewModel {
  constructor() {

    this.id, //: "3efeca2e-3a3e-4f04-a4ef-95ad0d034d95",
    this.displayName, //: "Parleondo",
    this.givenName, //: "Peter",
    this.surname, //: "Müller",
    this.companyName, //: "Coniclabs",
    this.companyId, //: "f0115cf2-c508-47fd-bba1-7dfd48313c6d",
    this.eMail, //: "Pacsterb@Hotmail.com",
    this.role, // id of role (int)
    this.image //: ""
  }
}



export class UserRoleViewModel {
  constructor(name = "", description = "") {

    this.id, //
    this.name = name, //: "e.g. admin",
    this.description = description, //: "describes the role",

    this.generalRights = new GeneralRightsViewModel(),
    this.editorRights = new EditorRightsViewModel(),
    this.publisherRights = new PublisherRightsViewModel(),
    this.u3mRights = new U3mRightsViewModel()

    this.creatorName = null,
    this.creatorId = null,
    this.createdOn = null,
    this.creatorCompany = null,
    this.creatorCompanyId = null
  }
}


export class GeneralRightsViewModel { //Note: RightsVielwModels don't need id (= part of UserRoleViewModel)
  constructor() {
    //this.id,
    this.manageCompanies = false,
    this.manageLicenses = false,
    this.manageUser = false,
    this.manageRoles = false
  }
}
export class EditorRightsViewModel { //Note: RightsVielwModels don't need id (= part of UserRoleViewModel)
  constructor() {
    this.manageProjects = false,
    this.inviteExtUsers = false, //share projects with extermal company
    this.uploadAssembly = false,
    this.deleteAssembly = false,
    this.createtMaterials = false,
    this.editMaterials = false,
    this.deletetMaterials = false,
    this.deployAssembly = false
  }
}
export class PublisherRightsViewModel {
  constructor() {
    this.manageModelversions = false,
    this.deleteModelversions = false,
    this.createAppKeys = false,
    this.deleteAppKeys = false
  }
}

export class U3mRightsViewModel {
  constructor() {
    this.viewMaterials = false,
    this.downloadMaterials = false,
    this.uploadMaterials = false,
    this.deleteMaterials = false
  }
}
