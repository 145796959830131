// import * as Msal from 'msal'
import * as Msal from '@azure/msal-browser'
import { authConfig, loginRequest, accessTokenRequest } from './../config/authConfig.js'
import store from './../../store/index.js'

const DO_LICENSE_CHECK = false
const NO_LICENSE_PAGE = "https://polyte.cloud/"


class AuthService {
  constructor () {
    // this.msal = new Msal.UserAgentApplication(
    this.msal = new Msal.PublicClientApplication(authConfig)
  }

  isUserLoggedIn () {
    return (this.msal.getAllAccounts().length > 0)
  }

  async login () {
    console.log('MMMMMMMMMMMMMMMMMM In Login --- ')
    let redirectResponse = await this.msal.handleRedirectPromise()
    console.log('MMMMMMMMMMMMMMMMMM In Login ---redirectResponse ', redirectResponse)

    let accessToken = await this.handleResponse(redirectResponse)
    //let accessTokenB = await this.aquireToken()
    // console.log('MMMMMMMMMMMMMMMMMM In Login --- \naccessTokenA ', accessToken, '\naccessTokenB ', accessTokenB)

    await store.dispatch('setAccessToken', accessToken)
    // await store.dispatch('setAccessToken', accessTokenB)
    let userData = await store.dispatch('fetchUserData')
    let companyLicense = await store.dispatch('fetchOwnCompanyLicenses')
   store.dispatch('fetchAllUserRoles')


    // console.log("Hier dal lizeoasere ", companyLicense);

    if(companyLicense.u3MViewerLicense == null){
      this.logout (NO_LICENSE_PAGE)
    }

    if(DO_LICENSE_CHECK){
      /////////Check LicenseData ////////
      let valData = {client: 'Publisher', company: userData.companyId}
      // console.log("Dipsaoaewjt awet ", valData);
      let result = await store.dispatch('validateLogIn', valData)
      // console.log("Dipsaoaewjt result ", result);
      if(result.data.validated != true){
        this.logout (NO_LICENSE_PAGE)
      }
    }
    return true;
  }

  async restoreUserData(){
    console.log('MMMMMMMMMMMMMMMMMM restoreUserData  --- start', this.isUserLoggedIn())
    // await new Promise((resolve) => {

      if (!this.isUserLoggedIn()) return false
      //TDO: what happens if Token expires?
      // console.log('MMMMMMMMMMMMMMMMMM restoreUserData  --- currentUser', store.getters.getCurrentUser.eMail, " acciToken ", store.getters.getAccessToken)
      //if((store.getters.getCurrentUser.eMail != null) && (store.getters.getAccessToken != null)) return true

      // if(store.getters.getAccessToken == null){
        let accessToken = await this.aquireToken() //Note: aquireToken always sends request, doesn't fetch from cache. Fix (Msal issue)
        // console.log('MMMMMMMMMMMMMMMMMM restoreUserData  --- aquired Token ', accessToken)
        store.dispatch('setAccessToken', accessToken)
      //}

      if(store.getters.getCurrentUser.eMail == null){
        let userData = await store.dispatch('fetchUserData') //with the accessToken
        // console.log('MMMMMMMMMMMMMMMMMM restoreUserData  --- storedUserData ', userData)
        store.dispatch('setUserCurrentUser', userData)
        let companyLicense = await store.dispatch('fetchOwnCompanyLicenses')
        store.dispatch('fetchAllUserRoles')
      }
      return true;
    // })
  }

  async handleResponse (response) {
    console.log('MMMMMMMMMMMMMMMMMM handleResponse 44  --- ', response)

    // const loginRequest =
    // {
    //   // openid
    //   scopes: ['offline_access openid ', config.auth.clientId] // Client_id
    //   // scopes: ["openid", "profile" "User.ReadWrite"]
    // }

    let accessToken = ''

    // let accountId = ''

    if (response !== null) {
      // accountId = response.account.homeAccountId
      accessToken = response.accessToken
    } else {
      const currentAccounts = this.msal.getAllAccounts()
      // console.log('MMMMMMMMMMMMMMMMMMMMMMMMM handleResponse - Accounts allready there --- ', currentAccounts)

      if (currentAccounts.length === 0) {
        // no accounts signed-in, attempt to sign a user in
        const result = await this.msal.loginRedirect(loginRequest)
        accessToken = result.accessToken
        // let accessToken = await this.msal.loginPopup(loginRequest);
      } else if (currentAccounts.length > 1) {
        // In case multiple accounts exist, select account
      } else if (currentAccounts.length === 1) {
        // accountId = currentAccounts[0].homeAccountId
        accessToken = null // currentAccounts[0].homeAccountId
      }
    }
    console.log('MMMMMMMMMMMMMMMMMM handleResponse accessToken  --- ', accessToken)
    return accessToken
  }

  async aquireToken () {
    //   if (this.msal.getAccount()) {
    //     var tokenRequest = {
    //         scopes: ["user.read", "mail.send"]
    //     };
    //     this.msal.acquireTokenSilent(tokenRequest)
    //         .then(response => {
    //             // get access token from response
    //             // response.accessToken
    //         })
    //         .catch(err => {
    //             // could also check if err instance of InteractionRequiredAuthError if you can import the class.
    //             if (err.name === "InteractionRequiredAuthError") {
    //                 return this.msal.acquireTokenPopup(tokenRequest)
    //                     .then(response => {
    //                         // get access token from response
    //                         // response.accessToken
    //                     })
    //                     .catch(err => {
    //                         // handle error
    //                     });
    //             }
    //         });
    // } else {
    //     // user is not logged in, you will need to log them in to acquire a token
    // }

    const redirectResponse = await this.msal.handleRedirectPromise()
    let accessToken = ''
    if (redirectResponse !== null) {
      // Acquire token silent success
      accessToken = redirectResponse.accessToken

      console.log('MMMMMMMMMMMMMMMMMMMMMMMMM ACCESSTOKEN 1 --- ', redirectResponse)
      // Call your API with token
      // callApi(accessToken);
    }
    else {
      // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
      const account = this.msal.getAllAccounts()[0]
      //var testo = this.msal.getTokenCache()
      console.log('MMMMMMMMMMMMMMMMMMMMMMMMM ACCESSTOKEN account --- ', account)

      accessTokenRequest.account = account
      accessTokenRequest.forceRefresh = false

      try {
        const accessTokenResponse = await this.msal.acquireTokenSilent(accessTokenRequest)
        accessToken = accessTokenResponse.accessToken
        // console.log('MMMMMMMMMMMMMMMMMMMMMMMMM ACCESSTOKEN 2 --> ', accessToken)
      }
      catch (error) {
        // console.log('MMMMMMMMMMMMMMMMMMMMMMMMM ERROR --> ', error)
        //e.g. if token has expired
        return this.logout()
      }

    }
    return accessToken
  }

  async logout (redirictUrl = null) {
    // this.msal._user = null
    console.log("Logging Out ....................................", this.msal);

    if(DO_LICENSE_CHECK){
      /////////Check LicenseData ////////
      let userData = await store.dispatch('fetchUserData')
      let valData = {client: 'Publisher', company: userData.companyId}
      console.log("fetchUserData .. ", valData);
      await store.dispatch('registerLogOff', valData)
      ////////////////////////////
    }

    this.msal.config.auth.postLogoutRedirectUri = redirictUrl || ""
    this.msal.logoutRedirect()
    // logoutRedirect
  }

  getUser () {
    return this.msal.getUser()
  }
}

const theAuthService = new AuthService()
export default theAuthService