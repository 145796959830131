import axios from 'axios'

const state = {
  accessToken: null,
  currentUser: {},
  companyUsers:[]
}

const mutations = {
  SET_ACCESS_TOKEN (state, payload) {
    state.accessToken = payload
  },

  SET_CURRENT_USER (state, payload) {
    state.currentUser = payload
  },


  SET_COMPANY_USERS (state, payload) {
    state.companyUsers = payload
  },
  ADD_COMPANY_USER (state, payload) {
    state.companyUsers.push(payload)
  },

  UPDATE_COMPANY_USER (state, payload) {
    console.log('Update Compoany User payload ', payload.updateUser)
    var index = state.companyUsers.findIndex(l => l.id == payload.updateUser.id);
    // if (index !== -1) {
      state.companyUsers[index] = payload.updateUser
  },
}

const actions = {
  setAccessToken (context, payload) {
    // console.log('setAccessToken: ', payload, ' context ', context)
    context.commit('SET_ACCESS_TOKEN', payload)
    return true
  },

  async fetchUserData (context) {
    console.log('Fetched CDN User')
    // return axios.get('/Account/UserData').then(
    let response = await axios.get('/UserData')
    console.log('[User Store] GOT USER DATA CDN DATA', response.data)
    context.commit('SET_CURRENT_USER', response.data.userData)
    return response.data.userData


  },

  fetchAllCompanyUsers (context) {
    console.log('Fetch all Company Users')
    // return axios.get('/Account/UserData').then(
    return axios.get('/Users').then(
    // return axios.get(`https://localhost:44377/Account/Users`).then(
      (response) => {
        console.log('[User Store] GOT USERS ', response.data)
        context.commit('SET_COMPANY_USERS', response.data)

        return response.data
      }
    )
  },

  setUserCurrentUser (context, payload) {
    // console.log('setUserCurrentUser: ', payload, ' context ', context)
    context.commit('SET_CURRENT_USER', payload)
    return true
  },

  async createUser (context, createUserObj) {
    console.log('create User ', createUserObj)

    let response = await axios.post('/User', createUserObj)
    // console.log('[User Store] createUser ', response.data)
    //Add company Name
    // let newUser = response.data.userDto
    // let company =  context.getters.getAllCompanys.find(d => d.id == createUserObj.companyId)
    // newUser.companyName = company.name

    context.commit('ADD_COMPANY_USER', response.data.userDto)
    return response.data
  },

  async updateUser (context, createUserObj) {
    console.log('Update User ', createUserObj)
    let response = await axios.patch('/User', createUserObj)
    // console.log('[User Store] updateUser ', response.data)
    context.commit('UPDATE_COMPANY_USER', { updateUser: response.data })
  },

  async resetUserPWD (context, userId) {
    let response = await axios.post(`/UserAuthResetPw/${userId}`)
    // console.log('resetUserPWD  ', response)
    return response.data;
  },

  async changeUserPWD (context, changeObj) { // gets {userId: cmptUserData.id, oldPwd: this.oldPwd, newPwd: this.newPwd1}
    //let changer = {oldPwd: changeObj.oldPwd, newPwd: changeObj.newPwd} //tdo: encrypt!

    let response = await axios.post(`/UserAuthChangePw/`, changeObj)
    // console.log('resetUserPWD  ', response)
    return response.data;
  },

}

const getters = {
  getAccessToken: (state) => state.accessToken,
  getCurrentUser: (state) => state.currentUser,
  getCurrentUserRole: (state, getters) => getters.getUserRoles.find( d=> d.id == state.currentUser.role) || {},
  getCurrentUserInitials: (state, getters) => {
    let letterGivenName = getters.getCurrentUser.givenName ? getters.getCurrentUser.givenName.charAt(0) : ''
    let letterSurname = getters.getCurrentUser.surname ? getters.getCurrentUser.surname.charAt(0) : ''
    return letterGivenName + letterSurname
  },
  getCompanyUsers: (state) => state.companyUsers
}

const loginModule = {
  state,
  mutations,
  actions,
  getters
}

export default loginModule;