import { createRouter, createWebHistory } from 'vue-router'
import AuthService from './../auth/msal/AuthService.js'

import Home from '../views/Home.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/user',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
  },
  {
    path: '/material/:materialId', //slug need to be used
    name: 'MaterialDetail',
    props: true,
    component: () => import(/* webpackChunkName: "materialDetail" */ '../views/MaterialDetail.vue')
  },
  {
    path: '/admin',
    name: 'AdminPanel',
    component: () => import(/* webpackChunkName: "adminPanel" */ '../views/AdminPanel.vue')
  },
  {
    path: '/licenseInfo',
    name: 'LicenseInfo',
    component: () => import(/* webpackChunkName: "licenseInfo" */ '../views/LicenseInfo.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {

  if (!AuthService.isUserLoggedIn()) {
    console.log('beforeEach. User is NOT logged in')
    await AuthService.login()
  } else
  {
    console.log('beforeEach. Restoring user data')
    await AuthService.restoreUserData()
  }

  // console.log('No Redirecting. Routing from ', from.path, '  to ', to.path)
  next()
})

export default router
