<template>
  <div class="add-material-btn">
    <button @click="addMaterial()" class="btn-round btn-green">
      <SystemIcons :type="'plusIcon'"/>
    </button>
  </div>
</template>

<script>
import { GLOBAL_MODAL_CONTENT } from '@/customCode/CustomObjects.js'
import SystemIcons from '@/components/elements/SystemIcons.vue'

export default {
  name: 'AddMaterialBtn',

  components: {
    SystemIcons
  },

  props: {
    data: Object,
    selectedCategory: String
  },

  data () {
    return {
      dropdownOpen: false
    }
  },

  computed: {},

  methods: {
    addMaterial() {
      this.$store.dispatch('showGlobalModal', { type: GLOBAL_MODAL_CONTENT.UPLOAD_MATERIAL } )
    }
  }
}
</script>

<style scoped lang="scss">
$input-height: 3.5rem;

.add-material-btn {
  position: fixed;
  left: 2.5rem;
  bottom: 2.5rem;
  background-color: var(--bg-secondary);
  border-radius: $input-height / 2;
  height: min-content;

  // @include respond-to('medium') {
  //   display: none;
  // }
}

</style>