

export class BaseLicense {
  constructor(category, name = "", description = "") {
    this.id,
    this.category = category,// "EDITOR", "PUBLISHER" || "U3M" <-for frontend
    this.name = name,
    this.description = description,

    this.startDate, //if license has time limit
    this.endDate, //if license has time limit

    this.maxUsers, //max user allowed to use application

    this.storageGB,
    this.viewsPerMonth, //bei Editor für publischer Mini, bei U3M Impressions

    this.enableUserAdmin, //in
    this.enableEditorLicensing, //in
    this.enablePublisherLicensing, //in
    this.enableU3MLicensing, //in


    this.creatorName, // creator name of license (string, in case id is deleted)
    this.creatorId, // creator (id) of license
    this.createdOn, //
    this.creatorCompany // Company that owns/manages license
    this.creatorCompanyId // Company id that owns/manages license
  }
}

export class EditorLicense extends BaseLicense {
  constructor(name = "", description = "") {
    super("EDITOR", name, description); // BaseLicense

    //Editor features
    this.manageProjects,
    this.editMaterials,
    this.enableDeployment
  }
}


export class PublisherLicense extends BaseLicense {
  constructor(name = "", description = "") {
    super("PUBLISHER", name, description); // BaseLicense

    //Publisher features
    this.displayWatermark,
    this.display3DInfo,
    this.createThumbnails
  }
}

export class U3MViewerLicense extends BaseLicense {
  constructor(name = "", description = "") {
    super("U3M", name, description); // BaseLicense

    this.downloadMaterial,
    this.uploadMaterial
  }
}