<template>
  <TheAppHeader/>
  <div class="mein-app-view">
    <router-view/>
  </div>
  <TheAppFooter/>

  <transition name="fade-modal">
    <TheGlobalModal v-if="cmptGlobalModalState.showWindow" />
  </transition>
  <AddMaterialBtn v-if="cmptShowAddButton" />
</template>

<script>
  import 'floating-vue/dist/style.css' // TODO: put styles into scss structure

  import TheAppHeader from '@/components/TheAppHeader.vue'
  import TheAppFooter from '@/components/TheAppFooter.vue'
  import TheGlobalModal from '@/modalViews/TheGlobalModal.vue'

  import AddMaterialBtn from '@/components/elements/AddMaterialBtn.vue'
  import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'


  export default {
    name: 'app',

    components: {
      TheAppHeader,
      TheAppFooter,
      TheGlobalModal,
      AddMaterialBtn
    },

    mixins:[displayAuthMixins],

    computed: {
      cmptShowAddButton () {
        return this.allowUploadMaterials()
      },
      cmptGlobalModalState () {
        return this.$store.getters.getGlobalModalState
      }
    },

    created () {
      document.title = "Fabrix - Material Cloud";
    },
    mounted(){
    }
  }
</script>

<style lang="scss">
@import "@/assets/scss/global-styles.scss";

.fade-modal {
  &-enter-active,
  &-leave-active {
    transition: opacity .2s ease-out;
    .modal {
      transition: transform .2s ease-out;
    }
  }

  &-enter-from {
    opacity: 0;
    .modal {
      transform: translateY(.5rem);
    }
  }
  &-leave-to {
    opacity: 0;
    .modal {
      transform: translateY(-.5rem);
    }
  }
}
</style>