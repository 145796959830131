// Enum for GlobalModalWindow state
export const GLOBAL_MODAL_CONTENT = {
  CREATE_UPDATE_USER: "Modal__CreateUpdateUser",
  CREATE_UPDATE_COMPANY: "Modal__CreateUpdateCompany",
  CREATE_UPDATE_LICENSE: "Modal__CreateUpdateLicense",
  CREATE_UPDATE_ROLE: "Modal__CreateUpdateRole",

  UPLOAD_MATERIAL: "Modal__UploadMaterial",
  DISPLAY_USERINFO: "Modal__UserInfo",
  DISPLAY_NO_LICENSE_FOUND : "Modal__NoLicenseFoundInfo"
};
Object.freeze(GLOBAL_MODAL_CONTENT);

