import axios from 'axios';


const state = {
  environments: [
    { id: 1, name: 'Productiv System', url: 'https://cdn.polyte.cloud/', color: 'blue' }, // ConicCloud
    { id: 2, name: 'Test System', url: 'https://polytecdntestserver.azurewebsites.net/', storagePath: 'https://u3mteststore.blob.core.windows.net/', color: 'DarkMagenta' }, // ConicCloud TestSystem
    { id: 3, name: 'Dev System (local)', url: 'https://localhost:44377/', storagePath: 'https://u3mdevstore.blob.core.windows.net/', color: 'red' }, // Development System (local db)
    // { id: 3, name: 'Dev System (local)', url: 'https://localhost:44377/', storagePath: 'https://u3mteststore.blob.core.windows.net/', color: 'red' }, // Development System (local db)
    // https://u3mteststore.blob.core.windows.net/u3mmaterials/CompanyId-55555/008ad57d-0259-4a97-b0b4-d7338d850c66@40033166/Preview/8104c3c9-578d-4398-bbc6-a4a6c4a1f9f3@Preview40033166.png
  ],

  storageContainer: Object.freeze({
    u3massemblies: 'u3massemblies',
    u3mmaterials: 'u3mmaterials',
    u3mdownloads: 'u3mdownloads',
  }),

  assetFolders: Object.freeze({
    materialFolder: 'Materials',
    textureFolder: 'Textures',
    shaderChunkFolder: 'ShaderChunks',
    shaderGroupFolder: 'ShaderGroups',
    imageFileFolder: 'ImageFiles',
    previewFolder: 'Preview'
  }),

  // activeEnvironmentId: localStorage.getItem('U3MViewerActiveEnv') || 1
  activeEnvironmentId: localStorage.getItem('U3MViewerActiveEnv') || 2
}

const mutations = {
  SET_ENVIRONMENT (state, env) {
    state.activeEnvironmentId = env.id
    axios.defaults.baseURL = env.url
  }
}

const actions = {
  setEnvironment (context, envId) {
    return new Promise((resolve, reject) => {
      let env = context.getters.getEnvironmentList.find(d => d.id == envId) || {}
      context.commit('SET_ENVIRONMENT', env)
      localStorage.setItem('U3MViewerActiveEnv', envId)
      resolve()
    })
  }
}

const getters = {
  getEnvironmentList: (state) => state.environments,
  getActiveEnvironment: (state) => state.environments.find(e => e.id == state.activeEnvironmentId) || {},
  getActiveEnvironmentID: (state) => state.activeEnvironmentId,
  getStorageContainer: (state) => state.storageContainer,
  getAssetFolder: (state) => state.assetFolders
}

const environmentModule = {
  state,
  mutations,
  actions,
  getters
}

export default environmentModule
