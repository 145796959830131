<template>
  <div class="modal" @keydown.esc="closeModal()" @dragover.prevent="fileDragged(true)" @dragleave="fileDragged(false)" @drop.prevent="fileDropped($event)">
    <div class="row header-row">
      <SystemIcons :type="'uTreeMIcon'" class="title-icon"/>
      <h1>Upload U3M Material</h1>
    </div>

    <div v-if="!isSubmitted">
      <!-- TODO: transfer dragged files => possible reference https://www.raymondcamden.com/2019/08/08/drag-and-drop-file-upload-in-vuejs -->

      <input ref="fileDlg" type="file" accept=".zip" style="display: none;" multiple @change="fileSelected()"/>
      <div class="drop-area" @click="$refs.fileDlg.click()">
        <div class="drop-info-container" :class="{'dashed' : fileIsDraggedOver && !fileIsDropped, 'small-drop-area' : computedFiles.length != 0}">
          <div v-if="!fileIsDraggedOver" class="drop-msg">
            <SystemIcons :type="'uTreeMFileIcon'" class="file-icon" />
            <p>drag <span class="bold">.ziped U3M</span> file here</p>
            <p>or click to open</p>
          </div>
          <p v-else-if="fileIsDraggedOver">ok, now drop 😉</p>
        </div>
      </div>

      <div class="modal-content file-list">
        <div class="file-row" v-for="(item, index) in computedFiles" :key="index">
          <SystemIcons :type="'zipFileIcon'" class="list-file-icon" />
          <p class="list-title"> {{ item.name }} </p>
          <p class="margin-r"> {{ (item.size / 1000000).toFixed(2) }} mb </p>
          <button class="btn-round" @click="removeFile(index)" ><SystemIcons :type="'xIcon'" class="btn-icon" /> </button>
        </div>
      </div>

      <div class="row">
        <button @click.prevent="closeModal()" class="btn-standard btn-gray">Cancel</button>
        <div class="spread"></div>
        <p v-if="warning" style="color:red">{{warning}}</p>
        <div class="spread"></div>
        <button :disabled="this.files.length == 0" @click.prevent="submit()" class="btn-standard btn-green">Upload <span class="btn-mat-list-length" v-if="computedFiles.length > 1"> {{ computedFiles.length }} </span> Material <span v-if="computedFiles.length > 1">s</span></button>
      </div>
    </div>

    <div v-else>
      <marquee>🚴 ...  Uploading Material...   🚴  Please don't refresh the page ....    🚴 .... Our drivers are doing their best to deliver your data-packages 🚴🚴🚴🚴 .....     🏇 </marquee>
    </div>

  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'

export default {
  name: 'Modal__UploadMaterial',

  components: {
    SystemIcons
  },

  props: {
    data: null,
    modalMode: String
  },

  data () {
    return {
      isSubmitted: false,
      fileIsDraggedOver: false,
      fileIsDropped: false,
      files: [],
      allowedFileTypes: [
        'application/zip',
        'application/x-zip',
        'application/x-zip-compressed'
      ],
      warning: ""
    }
  },

  computed: {
    // computedFileNames(){
    //   if(this.files.length == 0) return ""
    //   return this.files.map( m => m.name)
    // },
    computedFiles(){
      if(this.files.length == 0) return ""
      return this.files.map( m => m)
    }
  },

  methods: {

    removeFile(index){
      console.log("removeFile ", index);
      this.files.splice(index, 1);
    },

    async submit () {
      this.isSubmitted = true
      // console.log('Modal__UploadMaterial - submit - ', 'isSubmitted: ', this.isSubmitted);
      var formData = new FormData();

      //ToDo: Consider uploader Data
      formData.append("Sender", "Franz Beck");
      for (var i = 0, f; f = this.files[i]; i++) {
        formData.append("files", this.files[i]);
      }

      await this.$store.dispatch('uploadMaterials', formData)
      this.isSubmitted = false
      this.closeModal()
      this.$router.push({ name: 'Home' })
    },

    closeModal () {
      console.log("Close Modal. Is submited-> ", this.isSubmitted);
      if(this.isSubmitted)
        return
      this.$emit('closingModal')
    },

    fileDragged (state) {
      if (state == true) {
        this.fileIsDraggedOver = true
        this.fileIsDropped = false
        console.log('file is dragged over');
      } else if (state == false) {
        this.fileIsDraggedOver = false
        console.log('dragged over stoped');
      }
    },

     fileSelected(){
      this.fileIsDropped = true
      let selectedFiles = [...this.$refs.fileDlg.files]
      this.files = this.files.concat(selectedFiles);
    },

    fileDropped (ev ) {
      this.fileIsDropped = true
      this.fileIsDraggedOver = false

      let newFiles = []
      if (ev.dataTransfer.items) {
        // Using DataTransferItemList (Browser compatibility issue)
        for (var i = 0; i < ev.dataTransfer.items.length; i++) {
          console.log('ev.dataTransfer.items[i].type', ev.dataTransfer.items[i].type);

          if ((ev.dataTransfer.items[i].kind === 'file') && (this.allowedFileTypes.includes(ev.dataTransfer.items[i].type))) {
            newFiles.push(ev.dataTransfer.items[i].getAsFile());
          }
          else {
            this.warning = "Only .zip Files allowed"
            return
          }
        }
      }
      else {
        // Using DataTransfer (Browser compatibility issue)
        for (var i = 0; i < ev.dataTransfer.files.length; i++) {
          console.log('ev.dataTransfer.files[i].type', ev.dataTransfer.files[i].type);

          // if (ev.dataTransfer.files[i].type === "application/x-zip-compressed") {
          if ((this.allowedFileTypes.includes(ev.dataTransfer.files[i].type))) {
            newFiles.push(ev.dataTransfer.files[i])
          }
          else {
            this.warning = "Only .zip Files allowed"
            return
          }
        }
      }

      this.files = this.files.concat(newFiles);
      this.warning = ""
    }
  },

  created() {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style scoped lang="scss">
.modal {

  .drop-area {
    cursor: pointer;
    background: var(--bg-primary);
    // height: 15rem !important;
    border-radius: 1.25rem;
    border: none !important;
    padding: 1rem;
  
    .drop-info-container {
      width: 100%;
      // height: 100%;
      height: 15rem;
      border-radius: .5rem;
      border: dashed 2px transparent;
  
      @include flexbox;
      @include align-items(center);
      @include flex-just(center);
  
      &:hover {
        border-color: var(--color-secondary);
      }
      &.dashed {
        border-color: salmon;
      }
  
      &.small-drop-area {
        height: 7.5rem;
  
        .drop-msg {
          .file-icon {
            width: 2.5rem;
          }
        }
      }
  
      .drop-msg {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        
        .file-icon {
          width: 4.5rem;
          height: auto;
          margin-bottom: 1rem;
          fill: var(--accent-primary);
        }
  
        .bold {
          font-weight: 700;
        }
      }
    }
  }

  .file-list {
    width: 100%;
    margin: 2rem 0;
    max-height: 20rem !important;
    
    .file-row {
      background-color: var(--bg-primary);
      padding: .5rem 1rem;
      border-radius: .25rem;
      margin-bottom: .5rem;

      @include flexbox;
      @include align-items(center);

      .list-file-icon {
        height: 1.5rem;
        width: auto;
        margin-right: .5rem;
      }

      .margin-r {
        margin-right: .5rem;
      }

      .list-title {
        @include flex(auto, 1, 1);
        font-weight: 700;
      }
      .btn-icon {
        width: 60%;
        height: 60%;
        opacity: .75;
      }
    }
  }

  .btn-mat-list-length {
    margin: 0 .25rem;
  }
}
</style>