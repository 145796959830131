const state = {
  globalModal: {
    showWindow: false,
    contentType: null,
    modalMode: null,
    data: null
  },
  // showWipElements: true
  showWipElements: false
}

const mutations = {
  SHOW_GLOBAL_MODAL (state, payload) {
    state.globalModal.showWindow = true;
    state.globalModal.contentType = payload.type;
    state.globalModal.modalMode = payload.modalMode;
    state.globalModal.data = payload.data;
  },

  HIDE_GLOBAL_MODAL (state, payload) {
    state.globalModal.showWindow = false;
    state.globalModal.contentType = null;
    state.globalModal.modalMode = null;
    state.globalModal.data = null;
  },
}

const actions = {
  showGlobalModal (context, payload) {
    console.log(" showGlobalModal payload ", payload);
    return new Promise((resolve, reject) => {
      context.commit('SHOW_GLOBAL_MODAL', payload)
      resolve()
    })
  },

  hideGlobalModal (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('HIDE_GLOBAL_MODAL')
      resolve()
    })
  },
}

const getters = {
  getGlobalModalState: (state) => state.globalModal,
  getWipElementsState: (state) => state.showWipElements,
}

const appSettingsModule = {
  state,
  mutations,
  actions,
  getters
}

export default appSettingsModule;