<template>
  <div class="modal" @keydown.esc="closeModal()">
    <div class="row">
      <SystemIcons :type="'role'" class="title-icon"/>
      <h1>
        <span v-if="modalMode == 'add'"> Add new </span>
        <span v-else> Edit </span>
        <span> role </span>
        <span v-if="cmptDisplayRole.name">: </span>
        <span v-if="cmptDisplayRole.name && !nameEditMode" @dblclick="enterEditName()" class="role-label" :style="getRoleColor(cmptDisplayRole.name)"> {{cmptDisplayRole.name}} </span>
        <input v-if="nameEditMode" v-model="cmptDisplayRole.name" @blur="leaveEditName" @keypress.enter="leaveEditName" type="text" name="Enter name" placeholder="name" id="name" ref="nameInput" @change="warningText =null">
      </h1>
    </div>

    <div class="row header-row">
      <p class="dropdown-label">Company:</p>
      <div v-if="modalMode == 'add' && this.$store.getters.getAllCompanys.length > 1" class="row">
        <select :disabled="modalMode != 'add'" v-model="cmptDisplayRole.creatorCompanyId" id="companyName" @change="onCompanySelected">
          <option disabled value="">Please select one</option>
          <option
            v-for="(item, index) in cmptCompanies"
            :key = index
            :value="item.id"
          >{{ item.name }}</option>
        </select>
        <p class="info">This role will only be added to the selected company</p>
      </div>
      <p v-else> {{cmptDisplayRole.creatorCompany}} </p>
    </div>

    <div class="modal-content">
      <section class="main-section">
        <label v-if="modalMode == 'add'" for="name">Name</label>
        <input v-if="modalMode == 'add'" v-model="cmptDisplayRole.name" type="text" name="Enter name" placeholder="Enter name" id="name" @change="warningText =null">

        <label for="description">Description</label>
        <!-- <input v-model="cmptDisplayRole.description" type="text" name="Enter description" placeholder="Enter description" id="description"> -->
        <textarea v-model="cmptDisplayRole.description" name="info" placeholder="Enter description" id="description"></textarea>
      </section>

      <button @click="generalSectionsOpen = !generalSectionsOpen" class="btn-collape row" :class="{'is-open' : generalSectionsOpen}">
        <SystemIcons :class="{'collapsed' : !generalSectionsOpen}" :type="'dropdownChevronDown'"/>
        <p>General (Admin) Rights</p>
      </button>
      <section v-if="generalSectionsOpen" class="collapsible-section">
        <input class="checkbox-input" v-if="$store.getters.getWipElementsState" v-model="cmptDisplayRole.generalRights.manageCompanies" type="checkbox" id="manageCompanies">
        <label class="checkbox-label" v-if="$store.getters.getWipElementsState" for="manageCompanies">Manage Companies</label>
        <input class="checkbox-input" v-if="$store.getters.getWipElementsState" v-model="cmptDisplayRole.generalRights.manageLicenses" type="checkbox" id="manageLicenses">
        <label class="checkbox-label" v-if="$store.getters.getWipElementsState" for="manageLicenses">Manage Licenses</label>
        <input class="checkbox-input" v-model="cmptDisplayRole.generalRights.manageUser" type="checkbox" id="manageUser">
        <label class="checkbox-label" for="manageUser">Manage User</label>
        <input class="checkbox-input" v-model="cmptDisplayRole.generalRights.manageRoles" type="checkbox" id="manageRoles">
        <label class="checkbox-label" for="manageRoles">Manage Roles</label>
      </section>

      <template v-if="$store.getters.getWipElementsState">
        <button @click="editorSectionsOpen = !editorSectionsOpen" class="btn-collape row" :class="{'is-open' : editorSectionsOpen}">
          <SystemIcons :class="{'collapsed' : !editorSectionsOpen}" :type="'dropdownChevronDown'"/>
          <p>Editor Rights</p>
        </button>
        <section v-if="editorSectionsOpen" class="collapsible-section">
          <input class="checkbox-input" v-model="cmptDisplayRole.editorRights.manageProjects" type="checkbox" id="manageProjects">
          <label class="checkbox-label" for="manageProjects">Manage Projects</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.editorRights.inviteExtUsers" type="checkbox" id="inviteExtUsers">
          <label class="checkbox-label" for="inviteExtUsers">Invite External Users</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.editorRights.uploadAssembly" type="checkbox" id="uploadAssembly">
          <label class="checkbox-label" for="uploadAssembly">Upload Assembly</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.editorRights.deleteAssembly" type="checkbox" id="deleteAssembly">
          <label class="checkbox-label" for="deleteAssembly">Delete Assembly</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.editorRights.createtMaterials" type="checkbox" id="createtMaterials">
          <label class="checkbox-label" for="createtMaterials">Createt Materials</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.editorRights.editMaterials" type="checkbox" id="editMaterials">
          <label class="checkbox-label" for="editMaterials">Edit Materials</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.editorRights.deletetMaterials" type="checkbox" id="deletetMaterials">
          <label class="checkbox-label" for="deletetMaterials">Deletet Materials</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.editorRights.deployAssembly" type="checkbox" id="deployAssembly">
          <label class="checkbox-label" for="deployAssembly">Deploy Assembly</label>
        </section>

        <button @click="publisherSectionsOpen = !publisherSectionsOpen" class="btn-collape row" :class="{'is-open' : publisherSectionsOpen}">
          <SystemIcons :class="{'collapsed' : !publisherSectionsOpen}" :type="'dropdownChevronDown'"/>
          <p>Publisher Rights</p>
        </button>
        <section v-if="publisherSectionsOpen" class="collapsible-section">
          <input class="checkbox-input" v-model="cmptDisplayRole.publisherRights.manageModelversions" type="checkbox" id="manageModelversions">
          <label class="checkbox-label" for="manageModelversions">Manage Model Versions</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.publisherRights.deleteModelversions" type="checkbox" id="deleteModelversions">
          <label class="checkbox-label" for="deleteModelversions">Delete Model Versions</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.publisherRights.createAppKeys" type="checkbox" id="createAppKeys">
          <label class="checkbox-label" for="createAppKeys">Create AppKeys</label>
          <input class="checkbox-input" v-model="cmptDisplayRole.publisherRights.deleteAppKeys" type="checkbox" id="deleteAppKeys">
          <label class="checkbox-label" for="deleteAppKeys">Delete AppKeys</label>
        </section>
      </template>

      <button @click="u3mSectionsOpen = !u3mSectionsOpen" class="btn-collape row" :class="{'is-open' : u3mSectionsOpen}">
        <SystemIcons :class="{'collapsed' : !u3mSectionsOpen}" :type="'dropdownChevronDown'"/>
        <p>Fabrix Rights</p>
      </button>
      <section v-if="u3mSectionsOpen" class="collapsible-section">
        <input class="checkbox-input" v-model="cmptDisplayRole.u3mRights.viewMaterials" type="checkbox" id="viewMaterials">
        <label class="checkbox-label" for="viewMaterials">View Materials</label>
        <input class="checkbox-input" v-model="cmptDisplayRole.u3mRights.downloadMaterials" type="checkbox" id="downloadMaterials">
        <label class="checkbox-label" for="downloadMaterials">Download Materials</label>
        <input class="checkbox-input" v-model="cmptDisplayRole.u3mRights.uploadMaterials" type="checkbox" id="uploadMaterials">
        <label class="checkbox-label" for="uploadMaterials">Upload Materials</label>
        <input class="checkbox-input" v-model="cmptDisplayRole.u3mRights.deleteMaterials" type="checkbox" id="deleteMaterials">
        <label class="checkbox-label" for="deleteMaterials">Delete Materials</label>
      </section>
    </div>

    <div class="row">
      <button @click.prevent="closeModal()" class="btn-standard btn-gray">Cancel</button>
      <div class="spread"></div>
      <span v-if="warningText" style="color:red">{{ warningText }}</span>
      <div class="spread"></div>
      <button @click.prevent="submit()" class="btn-standard btn-green">Save Changes</button>
    </div>
  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'
import { UserRoleViewModel } from '@/customCode/AdministrationViewModels/AdminViewModels.js'

export default {
  name: 'Modal__CreateUpdateRole',

  components: {
    SystemIcons
  },

  props: {
    data: null,
    modalMode: String
  },

  data () {
    return {
      isSubmitted: false,
      creatorRole: new UserRoleViewModel(),
      updateableRole: null,
      warningText: null,
      nameEditMode: false,
      generalSectionsOpen: false,
      editorSectionsOpen: false,
      publisherSectionsOpen: false,
      u3mSectionsOpen: false,
    }
  },

  computed: {},

  methods: {
    onCompanySelected(){
      this.warningText = null
      let company = this.cmptCompanies.find(c => c.id == this.cmptDisplayRole.creatorCompanyId)
      this.cmptDisplayRole.creatorCompany = (company!= null)? company.name : null
    },
    submit () {
      this.isSubmitted = true
      // console.log('Modal__CreateUpdateRole - submit - ', 'isSubmitted: ', this.isSubmitted);

      if(!this.checkValidity()){
        this.isSubmitted = false
        return
      }

      if(this.modalMode == 'add'){
        this.CreateRole()
      }

      else {
        this.UpdateRole()
      }

      this.closeModal()
    },

    checkValidity(){
      // console.log('Modal__CreateUpdateRole - checkValidity - ', this.cmptDisplayRole);
      if((this.cmptDisplayRole.name == null) || (this.cmptDisplayRole.name.length == 0)){
        this.warningText = "Set Name"
        return false
      }
      if((this.cmptDisplayRole.creatorCompanyId == null) || (this.cmptDisplayRole.creatorCompanyId.length == 0)){
        this.warningText = "Set Company"
        return false
      }
      return true
    },

    CreateRole(){
      // console.log("createRole ", this.creatorRole);
      this.$store.dispatch('createRole', this.cmptDisplayRole)
    },

    UpdateRole(){
      this.$store.dispatch('updateRole', this.cmptDisplayRole)
    },

    closeModal () {
      this.isSubmitted = false
      this.$emit('closingModal')
    },

    enterEditName () {
      this.nameEditMode = true
      setTimeout(() => {  this.$refs.nameInput.focus(); }, 10);
    },

    leaveEditName () {
      this.nameEditMode = false
    },

    getRoleColor(stringInput) {
      let stringUniqueHash = [...stringInput].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);
      let hsl = `hsl(${stringUniqueHash % 360}, 95%, 75%)`
      return `background-color: ${hsl}`;
    }
  },

  computed: {
    cmptCompanies(){
      return this.$store.getters.getAllCompanys
    },

    cmptDisplayRole(){
      // if in edit mode
      if(this.modalMode != 'add') {
        if(this.updateableRole == null) {
          this.updateableRole = { ...this.data } //return clone (else changes = localy persistant)
        }
        console.log("cmptRole ", this.updateableRole);
        return this.updateableRole
      }

      //if in create mode
      console.log("cmptRole ", this.creatorRole);
      return this.creatorRole
    }
  },

  created() {},
  mounted() {
    if(this.modalMode == 'add') {
      if (this.$store.getters.getAllCompanys.length == 1) {
        let company = this.cmptCompanies.find(c => c.id == this.$store.getters.getCurrentUser.companyId)
        this.creatorRole.creatorCompanyId = (company!= null)? company.id : null
        this.creatorRole.creatorCompany = (company!= null)? company.name : null
      }
    }
  },

  beforeUnmount() {}
}
</script>

<style scoped lang="scss">
.role-label {
  cursor: text;
  padding: .2rem .75rem;
  border-radius: .5rem;
  white-space: nowrap;
}

.modal-content {
  @include flexbox;
  @include flex-direction(column);
  padding-bottom: 1rem;

  input {
    background-color: var(--bg-primary);
    margin-bottom: 1rem;
  }

  .main-section {
    padding: .25rem .25rem 2rem .25rem;
    @include flexbox;
    @include flex-direction(column);
  }

  .btn-collape {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0 0 .5rem 0;
    font-size: inherit;

    &:hover, &.is-open {
      p {
        background-color: var(--bg-primary);
      }
    }

    p {
      padding: .25rem .5rem;
      border-radius: .25rem;
    }

    svg {
      width: .75rem;
      height: .75rem;
      margin-right: .5rem;

      &.collapsed {
        transform: rotate(-90deg);
      }
    }
  }

  .collapsible-section {
    margin-bottom: 2rem;

    .checkbox-label {
      display: inline-block;
      float: left;
    }

    .checkbox-input {
      margin-left: 1.5rem;
      margin-right: .5rem;
      float: left;
      clear: both;
    }
  }

}
</style>