<template>
  <section>

    <header>
      <div class="row">

        <div class="search margin-r">
          <SystemIcons :type="'searchIcon'" class="magnifying-icon"/>
          <input
            id="search-input"
            class="serach-input"
            ref="filterInput"
            type="text"
            :placeholder="'Search ' + cmptNumberOfFilteredMaterials + ' materials'"
            v-model="searchString"
            @keydown.esc="clearSearchString()"
            @change="saveSerachString()"
          >
          <button v-if="searchString.length > 0" @click="clearSearchString()" class="search-controls btn-clear">
            <SystemIcons :type="'xIcon'" class="x-icon"/>
          </button>
        </div>

        <DropdownElement
          :data="dropdown_Category"
          :selectedCategory="dropdown_Category.current"
          v-on:onSelection="setDropdownCategory ($event)"
          class="category-dropdown margin-r"
        />
        <!-- <DropdownElement :data="dropdown_Type" /> -->

      </div>

      <div class="row">
        <button @click="cycleSort" class="btn-sort btn-clear">
          <span class="bold-text">Sort:</span>
          <p> <span v-if="sort == 0"> None </span> <span v-else-if="sort == 1"> Ascending </span> <span v-else-if="sort == -1"> Descending </span></p>
          <SystemIcons v-if="sort != 0" :type="'dropdownChevronDown'" class="dropdown-chevron" :class="{ 'chevron-up' : sort == -1}"/>
        </button>
        <div class="spread"></div>
        <p v-if="searchString.length > 0"> filtered results {{cmptFilteredMaterialList.length}} </p>
        <div class="spread"></div>
        <button @click="toggleListDisplay" class="btn-grid-view btn-clear">
          <span class="bold-text">View:</span>
          <SystemIcons v-if="gridView" :type="'gridViewIcon'" class="grid-view-icon"/>
          <SystemIcons v-else :type="'listViewIcon'" class="grid-view-icon"/>
          <span>{{gridView ? 'Grid' : 'List'}}</span>
        </button>
      </div>

    </header>

    <div class="grid-container" :class="{'list-view' : !gridView}">
      <MaterialListElement
        v-for="(item, index) in cmptFilteredMaterialList"
        :key="index"
        :data="item"
        :gridView="gridView"
      />
    </div>

    <div v-if="cmptFilteredMaterialList.length < cmptMaterialList.length" class="filter-results">
      <p v-if="cmptFilteredMaterialList.length == 0" class="no-results-sign">0</p>

      <p v-if="cmptFilteredMaterialList.length > 0"> filtered results {{cmptFilteredMaterialList.length}} from {{cmptMaterialList.length}}</p>
      <p v-else> The selected filters lead to no result </p>
      <button @click="clearAllFilters()" class="btn-standard btn-white" :class="{'btn-red' : cmptFilteredMaterialList.length == 0}">
        Clear all filters
      </button>
    </div>

  </section>
</template>

<script>
import MaterialListElement from '@/components/elements/MaterialListElement.vue'
import DropdownElement from '@/components/elements/DropdownElement.vue'
import SystemIcons from '@/components/elements/SystemIcons.vue'

import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'

export default {
  name: 'Home',

  components: {
    MaterialListElement,
    DropdownElement,
    SystemIcons
  },

  mixins:[displayAuthMixins],
  data () {
    return {
      // searchString: '',
      searchString: this.$store.getters.getMaterialSearchString,
      // sort: 0,
      sort: this.$store.getters.getMaterialNameSorting,
      // gridView: true,
      gridView: this.$store.getters.getMaterialGridView,
      dropdown_Category: {
        selector: 'Category:',
        // current: 'All',
        current: this.$store.getters.getMaterialCategory,
        showIcon: true,
        list: [
          { itemName: 'Unknown' },
          { itemName: 'Other' },
          { itemName: 'Artwork' },
          { itemName: 'Elastics' },
          { itemName: 'Knit' },
          { itemName: 'Leather' },
          { itemName: 'Metal' },
          { itemName: 'Seam' },
          { itemName: 'Wood' },
          { itemName: 'Woven' },
        ]
      },
      // dropdown_Type: {
      //   selector: 'Type: ',
      //   current: 'All',
      //   showIcon: false,
      //   list: [
      //     { itemName: 'Material' },
      //     { itemName: 'Artwork' },
      //     { itemName: 'Other' },
      //   ]
      // },
    }
  },

  computed: {
    cmptMaterialList () {
      if(!this.allowViewMaterials())
        return []
      return this.$store.getters.getMaterials
    },

    cmptFilteredMaterialList () {
      let data = this.cmptMaterialList
      let selectedCategory = this.dropdown_Category.current != 'All' ? this.dropdown_Category.current : ''

      var filterdData = data.filter((data) => {
        let isInName = data.name.toLowerCase().includes(this.searchString.toLowerCase())
        let isInType = (data.materialType != null ) ? data.materialType.type.toLowerCase().includes(selectedCategory.toLowerCase()) : false
        return isInName && isInType
      })

      if (this.sort != 0) {
        return filterdData.sort((a,b) => {
          if (this.sort == 1) {
            return a.name.localeCompare(b.name)
          } else if (this.sort == -1) {
            return b.name.localeCompare(a.name)
          }
        })
      } else {
        return filterdData
      }
    },

    cmptNumberOfFilteredMaterials () {
      return this.cmptMaterialList.length <= this.cmptFilteredMaterialList.length ?  this.cmptFilteredMaterialList.length : this.cmptFilteredMaterialList.length + ' out of ' + this.cmptMaterialList.length
    },
  },

  methods: {
    clearAllFilters () {
      this.clearSearchString()
      this.setDropdownCategory('All')
    },
    clearSearchString () {
      this.searchString = ''
      this.saveSerachString ()
    },
    saveSerachString () {
      this.$store.dispatch('setMaterialSearchString', this.searchString);
    },
    toggleListDisplay () {
      if (this.gridView == true) {
        this.gridView = false
      } else {
        this.gridView = true
      }
      this.$store.dispatch('setMaterialGridView', this.gridView);
    },
    cycleSort () {
      if (this.sort == 0) {
        this.sort = 1
      } else if (this.sort == 1) {
        this.sort = -1
      } else if (this.sort == -1) {
        this.sort = 0
      }
      this.$store.dispatch('setMaterialNameSorting', this.sort);
    },
    setDropdownCategory (selectedCategory) {
      this.dropdown_Category.current = selectedCategory
      this.$store.dispatch('setMaterialCategory', selectedCategory);
    }
  },

  created() {
    // this.searchString = this.$store.getters.getMaterialSearchString
    // this.sort = this.$store.getters.getMaterialNameSorting
    // this.gridView = this.$store.getters.getMaterialGridView
    // this.dropdown_Category.current = this.$store.getters.getMaterialCategory
  },

  mounted() {
    if(this.$store.getters.getMaterials.length == 0) //Only load if not currently loaded
      this.$store.dispatch('fetchMaterials');

    this._keyListener = function(e) {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
        this.$refs.filterInput.focus()
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this._keyListener);
  }
}
</script>

<style scoped lang="scss">
$input-height: 3.5rem;

.btn-clear {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: inherit;
}

section {
  header {
    z-index: 100;
    margin-bottom: 2rem;
    top: $header-height;

    .row {
      margin-bottom: .5rem;
    }

    .search {
      @include flex(auto, 1, 1);
      position: relative;
      background-color: var(--bg-secondary);
      border-radius: $input-height / 2;
      widows: 80%;

      @include flexbox;
      @include align-items(center);

      input {
        @include flex(auto, 1, 1);
        height: $input-height;
        background: none;
        border-radius: $input-height / 2;
        padding: .5rem 1.5rem .5rem 3rem;
        font-size: 1.25rem;
        overflow: visible;
        background-clip: padding-box;
        border: 1px solid var(--bg-secondary);

        transition: border-color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;

        &:focus, &:active {
          background-color: #fff;
          border-color: var(--accent-primary);
          outline: 0;
          box-shadow: 0 0 0 .2rem var(--accent-primary-light);
        }
      }

      .magnifying-icon {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        width: 1.25rem;
        height: 1.5rem;
        margin: 1rem;
        fill: var(--color-secondary);
      }

      .search-controls {
        right: 0;
        position: absolute;

        &.search-icon, .x-icon {
          fill: var(--color-secondary);
        }
        &.search-icon {
          pointer-events: none;
          width: 1.25rem;
          height: 1.25rem;
          margin: 1rem;
        }
        .x-icon {
          width: 1rem;
          height: 1rem;
          margin: 1rem 1.25rem 1rem 1rem;
        }
      }
    }

    // .category-dropdown {
    //   min-width: 15rem;

    //   @include respond-to('small') {
    //     min-width: 5rem;
    //   }
    // }

    .btn-sort, .btn-grid-view {
      padding: .5rem .5rem .5rem 0;

      @include flexbox;
      @include align-items(center);

      .bold-text {
        font-weight: 700;
        margin-right: .25rem;
      }

      .dropdown-chevron {
        width: .6rem;
        height: auto;
        margin: .25rem 0 0 .5rem;

        &.chevron-up {
          transform: rotate(180deg);
        }
      }

      .grid-view-icon {
        margin: .1rem .25rem 0 .25rem;
        width: 1rem;
        height: 1rem;
      }
    }

    .margin-r {
      margin-right: .5rem;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0,1fr));

    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;

    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;

    &.list-view {
      grid-template-columns: repeat(1, minmax(0,1fr));
      grid-row-gap: .5rem;
      row-gap: .5rem;
    }

    @include respond-to('large') {
      grid-template-columns: repeat(3,minmax(0,1fr));
    }

    @include respond-to('medium') {
      grid-template-columns: repeat(2,minmax(0,1fr));
    }

    @include respond-to('small') {
      grid-template-columns: repeat(1, minmax(0,1fr));
    }
  }

  .filter-results {
    text-align: center;
    width: 100%;

    p {
      margin: 3rem 0 1rem 0;
    }

    .no-results-sign {
      color: var(--color-secondary);
      font-weight: 600;
      font-size: 17rem;
      line-height: 1;
      margin: 0;
      padding: 0;
    }
  }
}
</style>