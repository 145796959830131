<template>
  <router-link :to="cmptLinkTarget"  class="list-element" :class="[cmptGridView]">
  <!-- <router-link :to="{name: 'MaterialDetail', params: { materialId: data.id, slug: 'Bert' } }"  class="list-element" :class="[cmptGridView]"> -->
    <div class="thumbnail-container">
      <!-- <SystemIcons :type="'uTreeMIcon'" class="bg-icon"/> -->
      <div class="thumbnail" :style="{'background-image': 'url(' + cmptPreviewUrl + ')'}" :class="{'no-image' : cmptPreviewUrl == ''}"> <p v-if="cmptPreviewUrl == ''">Material preview image</p> </div>
    </div>
    <div class="row">
      <SystemIcons :type="'category' + cmptCategory + ''" class="category-icon" v-tooltip="{content: 'category: ' + cmptCategory + '', distance: 10}"/>
      <div>
        <p class="title"> {{ data.name }} </p>
        <p class="category"> {{ cmptCategory }} </p>
      </div>
    </div>
  </router-link>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'

export default {
  name: 'MaterialListElement',

  components: {
    SystemIcons
  },

  props: {
    data: Object,
    gridView: Boolean
  },

  computed: {
    cmptLinkTarget () {
      return `/material/${this.data.id}`
    },

    cmptGridView () {
      return this.gridView ? 'grid-view' : 'list-view'
    },

    cmptCategory () {
      return (this.data.materialType != null) ? this.data.materialType.type : 'Unknown'
    },

    cmptPreviewUrl(){
      let preview = this.data.assemblyPreview || this.data.materialPreview

      if((preview == null) || (preview.length == 0))
        return ""

      let basePath = this.$store.getters.getActiveEnvironment.storagePath
      let container = this.$store.getters.getStorageContainer.u3mmaterials
      let folder = this.$store.getters.getAssetFolder.previewFolder
      //e.g. https://u3mteststore.blob.core.windows.net/u3mmaterials/CompanyId-55555/10a76f94-ae07-4af0-8d35-eb2adcb040ba@40084703/Preview/bc708000-1391-46f5-9dad-98ca9723977d@40084703.png
      return `${basePath}${container}/${this.data.companyId}/${this.data.storeageFolder}/${folder}/${preview}`
    }

  }
}
</script>

<style scoped lang="scss">
.list-element {
  cursor: pointer;
  border-radius: .75rem;
  transition: transform .2s ease-out;
  padding: .5rem;
  height: fit-content;

  .thumbnail-container {
    position: relative;
    overflow: hidden;

    .thumbnail {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 12rem;
      transition: transform .2s ease-out;

      @include flexbox;
      @include align-items(center);
      @include justify-content(center);

      &.no-image {
        background-color: var(--bg-secondary);
      }

      p {
        opacity: .5;
      }
    }

    .bg-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      height: 100%;
      opacity: .05;
    }
  }

  // .row {
  //   @include flexbox;
  //   @include align-items(center);
  // }

  .category-icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 1.25rem;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .category {
    line-height: 1;
  }

  &.grid-view {

    &:hover {
      .thumbnail-container {
        box-shadow: 0 .5rem .5rem .25rem rgba(0, 0, 0, 0.075);

        .thumbnail {
          transform: scale(1.05);
        }
      }
    }

    .thumbnail-container {
      width: 100%;
      height: 50%;
      margin-bottom: .5rem;
      border-radius: .5rem;
      box-shadow: 0 0 .5rem .25rem rgba(0, 0, 0, 0.025);
      background-color: var(--bg-tertiary);

      transition: .2s background-color ease-out,
                  .2s box-shadow ease-out;
    }
  }

  &.list-view {
    height: 5rem; // TEMP
    background-color: var(--bg-tertiary);
    box-shadow: 0 0 .5rem .25rem rgba(0, 0, 0, 0.025);
    @include flexbox;

    transition: .2s background-color ease-out,
                .2s box-shadow ease-out;

    &:hover {
      background-color: var(--bg-secondary);
      box-shadow: 0 .5rem .5rem .25rem rgba(0, 0, 0, 0.075);
    }

    .thumbnail-container {
      width: 7.5rem;
      height: 100%;
      margin-right: 1rem;
      border-radius: .25rem;
      background-color: var(--bg-secondary);

      p {
        font-size: .5rem;
        opacity: .5;
      }

      .thumbnail {
        height: 100%;
        &.no-image {
          background-color: var(--bg-primary);
        }
      }
    }
  }
}
</style>