

export const displayAuthMixins = {

  methods: {
    hasU3MLicense () {
      //Evaluate company license
      return ((this.$store.getters.getOwnCompanyLicenses != null) && (this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense != null))
    },

    allowEditorLicensing () {
      //Evaluate company license
      return ((this.$store.getters.getOwnCompanyLicenses != null) && (this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense != null)
      && (this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.enableEditorLicensing == true))
    },
    allowPublisherLicensing () {
      //Evaluate company license
      return ((this.$store.getters.getOwnCompanyLicenses != null) && (this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense != null)
      && (this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.enablePublisherLicensing == true))
    },
    allowU3MLicensing () {
      //Evaluate company license
      return ((this.$store.getters.getOwnCompanyLicenses != null) && (this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense != null)
      && (this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.enableU3MLicensing == true))
    },

    // General Rights .............
    allowManageCompanies () {
      // if(this.$store.getters.getWipElementsState == false)
      //   return false;

      if(!this.hasU3MLicense())
        return false

      if(!this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.enableUserAdmin)
        return false

      //Evaluate user role
      if(this.$store.getters.getCurrentUserRole.generalRights == null)
        return false;

      return this.$store.getters.getCurrentUserRole.generalRights.manageCompanies
    },

    allowManageLicenses () {
      // if(this.$store.getters.getWipElementsState == false)
      //   return false;

      if(!this.hasU3MLicense())
        return false

      if(!this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.enableUserAdmin)
        return false

      //Evaluate user role
      if(this.$store.getters.getCurrentUserRole.generalRights == null)
        return false;

      return this.$store.getters.getCurrentUserRole.generalRights.manageLicenses
    },

    allowManageUser () {
      // if(this.$store.getters.getWipElementsState == false)
      //   return false;

      if(!this.hasU3MLicense())
        return false

      if(!this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.enableUserAdmin)
        return false

      //Evaluate user role
      if(this.$store.getters.getCurrentUserRole.generalRights == null)
        return false;

      return this.$store.getters.getCurrentUserRole.generalRights.manageUser
    },
    allowManageRoles () {
      // if(this.$store.getters.getWipElementsState == false)
      //   return false;

      if(!this.hasU3MLicense())
        return false

      if(!this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.enableUserAdmin)
        return false

      //Evaluate user role
      if(this.$store.getters.getCurrentUserRole.generalRights == null)
        return false;

      return this.$store.getters.getCurrentUserRole.generalRights.manageRoles
    },



    // U3M Rights .............
    allowViewMaterials () {
      // if(this.$store.getters.getWipElementsState == false)
      //   return false;

      if(!this.hasU3MLicense())
        return false

      // if(!this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.uploadMaterial)
      //   return false

      //Evaluate user role
      if(this.$store.getters.getCurrentUserRole.u3mRights == null)
        return false;

      return this.$store.getters.getCurrentUserRole.u3mRights.viewMaterials
    },

    allowUploadMaterials () {
      console.log("We made it...1");
      if(this.$store.getters.getWipElementsState == false)
        return false;

        console.log("We made it...2 ", this.hasU3MLicense());
      if(!this.hasU3MLicense())
        return false

        console.log("We made it...3");
      if(!this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.uploadMaterial)
        return false

        console.log("We made it...4 ", this.$store.getters.getCurrentUserRole);
      //Evaluate user role
      if(this.$store.getters.getCurrentUserRole.u3mRights == null)
        return false;

      console.log("We made it... ", this.$store.getters.getCurrentUserRole.u3mRights.uploadMaterials);

      return this.$store.getters.getCurrentUserRole.u3mRights.uploadMaterials
    },

    allowDownloadMaterials () {
      // if(this.$store.getters.getWipElementsState == false)
      //   return false;

      if(!this.hasU3MLicense())
        return false

      if(!this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.downloadMaterial)
        return false

      //Evaluate user role
      if(this.$store.getters.getCurrentUserRole.u3mRights == null)
        return false;

      return this.$store.getters.getCurrentUserRole.u3mRights.downloadMaterials
    },

    allowDeleteMaterials () {
      if(this.$store.getters.getWipElementsState == false)
        return false;

      if(!this.hasU3MLicense())
        return false

      // if(!this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense.uploadMaterial)
      //   return false

      //Evaluate user role
      if(this.$store.getters.getCurrentUserRole.u3mRights == null)
        return false;

      return this.$store.getters.getCurrentUserRole.u3mRights.deleteMaterials
    },

  },

  computed: {
    // cmptActiveCameraSet(){
    //   return this.$store.getters.getActiveCameraSet || {}
    // },
  },
  mounted() {

  },
}