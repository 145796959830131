
import axios from 'axios'
import store from './store/index.js'

// Custom Axios Override, for servercalls

// Add request interceptor for Tokens

axios.interceptors.request.use(
  config => {
    //   const token = localStorageService.getAccessToken();
    const token = store.getters.getAccessToken
    // console.log('MMMMMMMMMMMMMMMMMM getAccessToken: ', token)
    // console.log('MMMMMMMMMMMMMMMMMM config: ', config)
    if (token != null) {
      if(config.responseType != 'blob') //No AuhtHeader token when fetching from storage
        config.headers.Authorization = 'Bearer ' + token
    }
    // console.log("DDDDDDDDDDDDDDDDDD TOKEN ....... ", token, "store ", store);
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  })

export default axios
