<template>
  <nav>
    <router-link to="/" class="home-icon" v-tooltip="{content: 'Back to overview', distance: 10}"> <SystemIcons :type="'uTreeMIcon'" class="u-three-m-logo"/> <SystemIcons :type="'fabrixLogo'" class="fabrix-logo"/> </router-link>
    <p class="cloud-title">{{cmptUserData.companyName}} Material Cloud</p>
    <div class="spread"></div>

    <!-- LBA: Added here for Development purpose ----------------------- -->
    <select class='dev-mode-select' v-if="$store.getters.getWipElementsState" v-model="activeEnvironment" @change="onEnvironmentChanged" >
      <option value= "" disabled>Select Environment</option>
      <option
        v-for="(item, index) in cmptEnvironments"
        :key = index
        :value="item.id"
      > {{ item.name }} </option>
    </select>
    <!-- ------------------------------------------------------------- -->


    <VMenu :distance="10" :placement="'bottom-end'" >
      <button class="btn-standard with-icon btn-white">
        <SystemIcons :type="'userIcon'"/>
        <!-- <p> {{cmptGetCurrentUserInitials}} </p> -->
        <p> {{cmptUserData.displayName}} </p>
      </button>

      <template #popper>

        <ul class="v-dropdown-content">
          <li class="v-dropdown-header-big">
            <!-- {{cmptUserData}} -->
            <p class="display-name"> {{cmptUserData.displayName}}</p>
            <p class="title"> {{cmptUserData.givenName}} {{cmptUserData.surname}} </p>
            <p class="title"> {{cmptUserData.companyName}} </p>
            <div class="seperator"></div>
            <p> {{cmptUserData.eMail}} </p>
          </li>
          <li class="v-dropdown-header-secondary">
            <p class="lable-role" :style="getRoleColor(cmptUserRoleName)"> {{cmptUserRoleName}} </p>
          </li>
          <li> <router-link to="/user">My Profile</router-link> </li>
          <li v-if="cmptEnableAdminPanel"> <router-link to="/admin">Admin Panel</router-link> </li>
          <li v-if="$store.getters.getWipElementsState"> <router-link to="/licenseInfo">License Info</router-link> </li>
          <li> <button @click="logout()" class="btn-standard"> log out </button> </li>
        </ul>

      </template>
    </VMenu>

  </nav>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'

import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'

export default {
  name: 'TheAppHeader',

  components: {
    SystemIcons
  },

  data () {
    return {
      activeEnvironment: this.$store.getters.getActiveEnvironmentID
    }
  },

  mixins:[displayAuthMixins],

  methods: {
    logout () {
      this.$AuthService.logout();
    },

    onEnvironmentChanged () {
      this.$store.dispatch('setEnvironment', this.activeEnvironment)
      setTimeout(() => {  location.reload(); }, 800);
    },

    getRoleColor(stringInput) {
      let stringUniqueHash = [...stringInput].reduce((acc, char) => {
          return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);
      let hsl = `hsl(${stringUniqueHash % 360}, 95%, 75%)`
      return `background-color: ${hsl}`;
    }
  },


  computed: {
    cmptUserData(){
      // console.log("cmptRoleName cmptUserData", this.$store.getters.getCurrentUser);
      return this.$store.getters.getCurrentUser
    },
    cmptEnableAdminPanel(){
      return (this.allowManageCompanies() || this.allowManageLicenses() || this.allowManageUser() || this.allowManageRoles())
    },
    cmptGetCurrentUserInitials () {
      return this.$store.getters.getCurrentUserInitials
    },
    // cmptRoleName(){
    //   let userrole = this.$store.getters.getUserRoles.find(r => r.id == this.cmptUserData.role)
    //   // console.log("cmptRoleName this.cmptUserData", this.cmptUserData);
    //   return userrole? userrole.name : "- no role -"
    // },
    cmptUserRoleName(){
      return this.$store.getters.getCurrentUserRole.name || ""
    },
    cmptEnvironments () {
      return this.$store.getters.getEnvironmentList
    }
  }
}
</script>

<style scoped lang="scss">
nav {
  z-index: 1000;
  background-color: var(--bg-header);
  padding: 0 $desktop-padding;
  height: $header-height;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  // box-shadow: 0 0 .5rem 1rem rgba(0, 0, 0, .015);

  @include flexbox;
  @include align-items(center);

  @include respond-to('medium') {
    padding: 0 $tablet-padding;
  }

  @include respond-to('small') {
    padding: 0 $mobile-padding;
  }

  .home-icon {
    @include flexbox;
    @include align-items(center);
  }

  .vizoo-logo {
    height: $header-height / 2;
    width: auto;
    margin-right: 1rem;
    // fill: var(--accent-primary);
    fill: var(--color-primary);
  }
  .u-three-m-logo {
    height: $header-height / 2;
    width: auto;
    margin-right: 1rem;
    // fill: var(--accent-primary);
    fill: var(--color-primary);
  }
  .fabrix-logo {
    height: $header-height / 2.5;
    width: auto;
    margin-right: 1rem;
    // fill: var(--accent-primary);
    fill: var(--color-primary);
  }

  .cloud-title {
    font-size: 1.2rem;
  }

  a {
    margin: 0 .5rem;
  }

  .dev-mode-select {
    margin-right: 1rem;
  }
}

 //LBA: Added here.....
  .disabled {
    pointer-events:none; //This makes it not clickable
    opacity:0.6;         //This grays it out to look disabled
}
</style>