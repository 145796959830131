import axios from 'axios'


const state = {
  companyList: [],
  activeCompanyId: null
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// MUTATIONS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mutations = {
  SET_COMPANY_LIST (state, payload) {
    console.log('Set Azure companyList collection -> ', payload)
    state.companyList = payload
  },

  ADD_COMPANY (state, payload) {
    console.log('Add Company payload ', payload.newCompany)
    // state.companyCollection.companys.unshift(payload); //
    state.companyList.push(payload.newCompany) // Considering sorting by list?
    // console.log('Add Company state.companyLis ', state.companyList)
  },

  UPDATE_COMPANY (state, payload) {
    console.log('Update Company payload ', payload.updateCompany)
    // state.companyCollection.companys.unshift(payload); //
    state.companyList.push(payload.updateCompany) // Considering sorting by list?
    var index = state.companyList.findIndex(l => l.id == payload.updateCompany.id);
    // if (index !== -1) {
      state.companyList[index] = payload.updateCompany
    // console.log('Add Company state.companyLis ', state.companyList)
  },
  // SET_ACTIVE_COMPANY_ID (state, payload) {
  //   state.activeCompanyId = payload
  // }
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// ACTIONS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const actions = {
  // setActiveCompanyID ({ commit }, activeCompanyId) { // Gui function
  //   commit('SET_ACTIVE_COMPANY_ID', activeCompanyId)
  // },

  /// //////////////////////////////////////
  fetchAllAzureCompanies ({ commit }) {
    return axios.get('/AzureCompanies')
      .then((response) => {
      // commit('SET_TOKEN', response.data.token);
        console.log('*fetchAllAzureCompanies: found companys on server.., ', response.data)
        commit('SET_COMPANY_LIST', response.data)
        // resolve(response)
        return response
      })
  },


  // fetchAllCompanyById ({ commit }, companyId) {

  //   if(axios.useLocalStorage == false){

  //     return axios.get(`/Companies/${companyId}`)
  //     .then((response) => {
  //       //commit('SET_TOKEN', response.data.token);
  //       console.log("*FetchingAllCompanys: found companys on server.., ", response.data);
  //       commit('SET_COMPANY_LIST', response.data)
  //       context.commit('SET_ACTIVE_COMPANY_ID', response.data.id)
  //       //resolve(response)
  //       return response;
  //     })
  //   }
  //   else
  //   {
  //     console.log("FetchingAllCompanys:.. from testdata ", TEST_DATA.companyCollection);
  //     commit('SET_COMPANY_LIST', TEST_DATA.companyList)
  //   }
  // },

  async createCompany (context, newCompany) { // gets {company: this.fields} //Old
    console.log('Creating company---------', newCompany)


    let azureResponse = await axios.post('/AzureCompanies', newCompany)
    let azureCompany = azureResponse.data

    console.log('*CompanyStore: got azure Company... ', azureCompany)

    //@@@@Temp for Backwards compatitbility. Store the company in DB, because of deployment
    //remove, after unifying editor, and publisher with azure companies (->Deployment!!)
    newCompany.azId = azureCompany.id
    // let companyResponse = await axios.post('/Companies', newCompany)
    // await axios.post('/Companies', newCompany)
    // // console.log('*CompanyStore: got companyResponse .., ', companyResponse)
    // context.commit('ADD_COMPANY', { newCompany: azureCompany })
    // context.commit('SET_ACTIVE_COMPANY_ID', response.data.id)
    // resolve(response)
    return azureCompany
  },

  async updateCompany (context, updateCompany) { // gets {company: this.fields} //Old
    console.log('Updating company---------', updateCompany)


    let azureResponse = await axios.patch('/AzureCompanies', updateCompany)
    let azureCompany = azureResponse.data

    console.log('*CompanyStore: got azure Company... ', azureCompany)

    //@@@@Temp for Backwards compatitbility. Store the company in DB, because of deployment
    //remove, after unifying editor, and publisher with azure companies (->Deployment!!)
    updateCompany.azId = azureCompany.id
    updateCompany.id = null //fix for nullable int(server)
    // let companyResponse = await axios.post('/Companies', updateCompany)
    // await axios.patch('/Companies', updateCompany)
    // // console.log('*CompanyStore: got companyResponse .., ', companyResponse)

    // context.commit('UPDATE_COMPANY', { updateCompany: azureCompany })

    // // context.commit('SET_ACTIVE_COMPANY_ID', response.data.id)
    // resolve(response)
    return azureCompany
  }
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////// GETTERS ////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getters = {
  getAllCompanys: state => state.companyList,
  // getActiveCompanyID: state => state.activeCompanyId,
  // getActiveCompany: state => state.companyList.find(p => p.id == state.activeCompanyId) || {}
}

const companyModule = {
  state,
  mutations,
  actions,
  getters
}

export default companyModule
