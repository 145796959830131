<template>
  <div class="modal" @keydown.esc="closeModal()">
    <div class="row">
      <SystemIcons :type="'company'" class="title-icon"/>
      <h1> <span v-if="modalMode == 'add'"> Add new company </span> <span v-else> Edit {{data.name}} </span> </h1>
    </div>

    <!-- <img :src= cmptLogoUri alt="testlogo" style="width:100px"> -->
    <!-- <label for="companyLogo">Choose company logo</label> -->
    <!-- <input type="file" id="companyLogo" name="companyLogo" accept="image/png, image/jpeg"> -->
    <div class="row header-row">
      <template v-if="modalMode != 'add'">
        <SystemIcons :type="'userIcon'" class="inline-icon"/>
        <p class="info"> <span class="bold">{{cmptDisplayCompany.userCount}}</span> Users assigned to this company </p>
      </template>
    </div>



    <div class="modal-content">
      <section class="main-section">
        <label for="name">Name</label>
        <input v-model="cmptDisplayCompany.name" type="text" name="name" placeholder="Enter name" id="name" @change="warningText =null">

        <label for="info">Description (optional)</label>
        <!-- <input v-model="cmptDisplayCompany.info" type="text" name="info" placeholder="Enter info" id="info"> -->
        <textarea v-model="cmptDisplayCompany.info" name="info" placeholder="Enter description" id="info"></textarea>
      </section>

      <section class="detail-section">
        <p class="title">Company details (optional)</p>

        <label for="license">Street</label>
        <input v-model="cmptDisplayCompany.street" type="text" name="street" placeholder="Enter street" id="street">

        <label for="number">Number</label>
        <input v-model="cmptDisplayCompany.number" type="text" name="number" placeholder="Enter number" id="number">

        <label for="zipCode">Zip code</label>
        <input v-model="cmptDisplayCompany.zipCode" type="text" name="zipCode" placeholder="Enter zipCode" id="zipCode">

        <label for="city">City</label>
        <input v-model="cmptDisplayCompany.city" type="text" name="city" placeholder="Enter city" id="city">

        <label for="country">Country</label>
        <input v-model="cmptDisplayCompany.country" type="text" name="country" placeholder="Enter country" id="country">

        <label for="website">Website</label>
        <input v-model="cmptDisplayCompany.website" type="text" name="website" placeholder="Enter website" id="website">
      </section>

      <section class="detail-section">
        <p class="title">Contact person (optional)</p>

        <label for="phone">Phone</label>
        <input v-model="cmptDisplayCompany.phone" type="text" name="phone" placeholder="Enter phone" id="phone">

        <label for="eMail">eMail</label>
        <input v-model="cmptDisplayCompany.eMail" type="text" name="eMail" placeholder="Enter eMail" id="eMail">
      </section>

      <section class="license-section">
        <div class="dropdown-block">
          <p v-if="$store.getters.getWipElementsState" for="editorLicenseId">Editor License</p>
          <select v-if="$store.getters.getWipElementsState" :disabled="cmptDisableLicenseSelection" v-model="cmptDisplayCompany.editorLicenseId" id="editorLicenseId" @change="warningText =null">
            <option disabled selected value="">Please select one</option>
            <option
              v-for="(item, index) in cmptEditorLicenses"
              :key = index
              :value="item.id"
            >{{ item.name }}</option>
            <option disabled="disabled">----------</option>
            <option value="-1" style="color:grey">None</option>
          </select>
        </div>

        <div class="dropdown-block">
          <p v-if="$store.getters.getWipElementsState" for="publisherLicenseId">Publisher License</p>
          <select v-if="$store.getters.getWipElementsState" :disabled="cmptDisableLicenseSelection" v-model="cmptDisplayCompany.publisherLicenseId" id="publisherLicenseId" @change="warningText =null">
            <option disabled selected value="">Please select one</option>
            <option
              v-for="(item, index) in cmptPublisherLicenses"
              :key = index
              :value="item.id"
            >{{ item.name }}</option>
            <option disabled="disabled">----------</option>
            <option value="-1" style="color:grey">None</option>
          </select>
        </div>

        <div class="dropdown-block">
          <p for="u3MViewerLicenseId">Fabrix License</p>
          <select v-model="cmptDisplayCompany.u3MViewerLicenseId" :disabled="cmptDisableLicenseSelection" id="u3MViewerLicenseId" @change="warningText =null">
            <option disabled selected value="">Please select one</option>
            <option
              v-for="(item, index) in cmptU3MEditorLicenses"
              :key = index
              :value="item.id"
            >{{ item.name }}</option>
            <option disabled="disabled">----------</option>
            <option value="-1" style="color:grey">None</option>
          </select>
        </div>
      </section>

      <!-- <label for="eMail">DeploymentFolder</label>
      <input v-model="cmptDisplayCompany.deploymentFolder" type="text" name="deploymentFolder" placeholder="Enter deploymentFolder" id="deploymentFolder" disabled> -->
    </div>

    <div class="row">
      <button @click.prevent="closeModal()" class="btn-standard btn-gray">Cancel</button>
      <div class="spread"></div>
      <span v-if="warningText" style="color:red">{{ warningText }}</span>
      <div class="spread"></div>
      <button @click.prevent="submit()" class="btn-standard btn-green">Save Changes</button>
    </div>
  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'
import { CompanyViewModel, UserRoleViewModel, GeneralRightsViewModel, EditorRightsViewModel,
 PublisherRightsViewModel, U3mRightsViewModel} from '@/customCode/AdministrationViewModels/AdminViewModels.js'

export default {
  name: 'Modal__CreateUpdateCompany',

  components: {
    SystemIcons
  },

  props: {
    data: null,
    modalMode: String,
  },

  data () {
    return {
      isSubmitted: false,
      creatorCompany: new CompanyViewModel(),
      updateableCompany: null,
      warningText: null,
    }
  },

  computed: {
    cmptDisplayCompany(){
      // if in edit mode
      if(this.modalMode != 'add') {
        if(this.updateableCompany == null) {
          this.updateableCompany = { ...this.data } //return clone (else changes = localy persistant)
        }
        // console.log("cmptComany ", this.updateableCompany);
        return this.updateableCompany
      }

      //if in create mode
      // console.log("cmptComany ", this.creatorCompany);
      return this.creatorCompany
    },

    cmptDisableLicenseSelection(){
      return this.cmptDisplayCompany.id == this.$store.getters.getCurrentUser.companyId

    },

    cmptLogoUri(){
      // return cmptdisplaycompany.logo
      return "Testlogo.png"
    },
    cmptEditorLicenses(){
      var currentUser = this.$store.getters.getCurrentUser
      if(this.cmptDisplayCompany.id != currentUser.companyId)
        return this.$store.getters.getLicenseList.filter(d => (d.category == "EDITOR" && d.creatorCompanyId == currentUser.companyId))
      else{
        let list = []
        if((this.$store.getters.getOwnCompanyLicenses != null) && (this.$store.getters.getOwnCompanyLicenses.editorLicense != null))
          list.push(this.$store.getters.getOwnCompanyLicenses.editorLicense)
        return list
      }
    },

    cmptPublisherLicenses(){
      var currentUser = this.$store.getters.getCurrentUser
      if(this.cmptDisplayCompany.id != currentUser.companyId)
        return this.$store.getters.getLicenseList.filter(d => (d.category == "PUBLISHER" && d.creatorCompanyId == currentUser.companyId))
      else{
        let list = []
        if((this.$store.getters.getOwnCompanyLicenses != null) && (this.$store.getters.getOwnCompanyLicenses.publisherLicense != null))
          list.push(this.$store.getters.getOwnCompanyLicenses.publisherLicense)
        return list
      }
    },

    cmptU3MEditorLicenses(){
      var currentUser = this.$store.getters.getCurrentUser
      if(this.cmptDisplayCompany.id != currentUser.companyId)
        return this.$store.getters.getLicenseList.filter(d => (d.category == "U3M" && d.creatorCompanyId == currentUser.companyId))
      else{
        let list = []
        if((this.$store.getters.getOwnCompanyLicenses != null) && (this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense != null))
          list.push(this.$store.getters.getOwnCompanyLicenses.u3MViewerLicense)
        return list
      }
    }
  },

  methods: {
    async submit () {
      this.isSubmitted = true

      //Ensure License is provided!

      if(!this.checkValidity()){
        this.isSubmitted = false
        return
      }

      if(this.modalMode == 'add'){

        let newCompany = await this.createCompany()
        this.createDefaultCompanyRoles(newCompany)
      }
      else {
        this.updateCompany()
      }

      // console.log('Modal__CreateUpdateCompany - submit - ', 'isSubmitted: ', this.isSubmitted);
      this.closeModal()
    },

    closeModal () {
      this.isSubmitted = false
      this.$emit('closingModal')
    },

    checkValidity(){

      console.log('Modal__CreateUpdateCompany - checkValidity - ', this.cmptDisplayCompany.editorLicenseId);
      if((this.cmptDisplayCompany.name == null) || (this.cmptDisplayCompany.name.length == 0)){
        this.warningText = "Set Name"
        return false
      }

      if(!this.isValidLicenseId(this.cmptDisplayCompany.editorLicenseId) &&
          !this.isValidLicenseId(this.cmptDisplayCompany.publisherLicenseId) &&
          !this.isValidLicenseId(this.cmptDisplayCompany.u3MViewerLicenseId))
      {
        this.warningText = "Select one or more Licens(es)"
        return false
      }

      // if((this.cmptDisplayCompany.publisherLicenseId == null) || (this.cmptDisplayCompany.publisherLicenseId.length == 0)){
      //   this.warningText = "Set PublisherLicenseId"
      //   return false
      // }

      // if((this.cmptDisplayCompany.u3MViewerLicenseId == null) || (this.cmptDisplayCompany.u3MViewerLicenseId.length == 0)){
      //   this.warningText = "Set U3mViewerLicenseId"
      //   return false
      // }

      return true
    },

    isValidLicenseId(licenseId){
      if((licenseId == null) || (licenseId.length == 0) || (licenseId == "-1"))
        return false

      return true
    },

    async createCompany(){
      // console.log("createCompany ", this.creatorCompany);
      let newCompany = await this.$store.dispatch('createCompany', this.cmptDisplayCompany)
      return newCompany
    },
    async createDefaultCompanyRoles(newCompany){
      console.log("createDefaultCompanyRoles ", newCompany);

      let generalRights = new GeneralRightsViewModel()
      generalRights.manageUser = true,
      generalRights.manageRoles = true

      let editorRights = new EditorRightsViewModel()
      editorRights.manageProjects = true
      editorRights.inviteExtUsers = true //share projects with extermal company
      editorRights.uploadAssembly = true
      editorRights.deleteAssembly = true
      editorRights.createtMaterials = true
      editorRights.editMaterials = true
      editorRights.deletetMaterials = true
      editorRights.deployAssembly = true

      let publisherRights = new PublisherRightsViewModel()
      publisherRights.manageModelversions = true
      publisherRights.deleteModelversions = true
      publisherRights.createAppKeys = true
      publisherRights.deleteAppKeys = true

      let u3mRights = new U3mRightsViewModel()
      u3mRights.viewMaterials = true
      u3mRights.downloadMaterials = true
      u3mRights.uploadMaterials = true
      u3mRights.deleteMaterials = true

      let u3mReadOnlyRights = new U3mRightsViewModel()
      u3mReadOnlyRights.viewMaterials = true


      //Create default roles
      let defaultAdminRole = new UserRoleViewModel("Admin", "Default Admin Role")
      defaultAdminRole.creatorCompany = newCompany.name,
      defaultAdminRole.creatorCompanyId = newCompany.id
      defaultAdminRole.generalRights = generalRights
      defaultAdminRole.editorRights = editorRights
      defaultAdminRole.publisherRights = publisherRights
      defaultAdminRole.u3mRights = u3mRights

      let defaultArtistRole = new UserRoleViewModel("Artist", "Default Artist Role")
      defaultArtistRole.creatorCompany = newCompany.name,
      defaultArtistRole.creatorCompanyId = newCompany.id
      // defaultArtistRole.generalRights = generalRights
      defaultArtistRole.editorRights = editorRights
      // defaultArtistRole.publisherRights = publisherRights
      defaultArtistRole.u3mRights = u3mRights

      let defaultViewerRole = new UserRoleViewModel("Fabrix Viewer", "Default Fabrix Viewer Role")
      defaultViewerRole.creatorCompany = newCompany.name,
      defaultViewerRole.creatorCompanyId = newCompany.id
      defaultViewerRole.u3mRights = u3mReadOnlyRights

      this.$store.dispatch('createRole', defaultAdminRole)
      this.$store.dispatch('createRole', defaultArtistRole)
      this.$store.dispatch('createRole', defaultViewerRole)

    },

    updateCompany(){
      this.$store.dispatch('updateCompany', this.cmptDisplayCompany)
    },
  },

  created() {},
  mounted() {
    if (this.$store.getters.getOwnCompanyLicenses == null)
      this.$store.dispatch("fetchOwnCompanyLicenses");
  },
  beforeUnmount() {}
}
</script>

<style scoped lang="scss">
.modal-content, .main-section, .detail-section {
  @include flexbox;
  @include flex-direction(column);

  input {
    background-color: var(--bg-primary);
    margin-bottom: 1rem;
  }
}

.modal-content {
  padding: .25rem;
}

.main-section {
  margin-bottom: 2rem;
}

.detail-section {
  padding-left: 1rem;
  margin-bottom: 2rem;

  .title {
    margin-bottom: 1rem;
    font-weight: 700;
  }
}


.license-section {
  margin-bottom: 7rem;
  @include flexbox;
  @include align-items(center);

  .dropdown-block {
    width: 33.333%;
    padding: 0 .5rem;
    @include flex(auto, 1, 1);

    select {
      width: 100%;
    }
  }
}

</style>