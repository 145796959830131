const state = {
  materialSearchString: localStorage.getItem('MaterialSearchString') || '',
  materialNameSorting: localStorage.getItem('MaterialNameSorting') || 0,
  materialGridView: localStorage.getItem('MaterialGridView') ? JSON.parse(localStorage.getItem('MaterialGridView')) : true,
  materialCategory: localStorage.getItem('MaterialCategory') || 'All',
  superAdminTab: localStorage.getItem('SuperAdminTab') || 'companies',
}

const mutations = {
  SET_MATERIAL_SEARCH_STRING (state, payload) {
    state.materialSearchString = payload;
  },
  SET_MATERIAL_NAME_SORTING (state, payload) {
    state.materialNameSorting = payload;
  },
  SET_MATERIAL_GRID_VIEW (state, payload) {
    state.materialGridView = payload;
  },
  SET_MATERIAL_CATEGORY (state, payload) {
    state.materialCategory = payload;
  },
  SET_SUPER_ADMIN_TAB (state, payload) {
    state.superAdminTab = payload;
  },
}

const actions = {
  setMaterialSearchString (context, payload) {
    context.commit('SET_MATERIAL_SEARCH_STRING', payload)
    var storageKey = 'MaterialSearchString';
    localStorage.setItem(storageKey, payload);
  },
  setMaterialNameSorting (context, payload) {
    context.commit('SET_MATERIAL_NAME_SORTING', payload)
    var storageKey = 'MaterialNameSorting';
    localStorage.setItem(storageKey, payload);
  },
  setMaterialGridView (context, payload) {
    context.commit('SET_MATERIAL_GRID_VIEW', payload)
    var storageKey = 'MaterialGridView';
    localStorage.setItem(storageKey, JSON.stringify(payload));
  },
  setMaterialCategory (context, payload) {
    context.commit('SET_MATERIAL_CATEGORY', payload)
    var storageKey = 'MaterialCategory';
    localStorage.setItem(storageKey, payload);
  },
  setSuperAdminTab (context, payload) {
    context.commit('SET_SUPER_ADMIN_TAB', payload)
    var storageKey = 'SuperAdminTab';
    localStorage.setItem(storageKey, payload);
  },
}

const getters = {
  getMaterialSearchString: state => state.materialSearchString,
  getMaterialNameSorting: state => state.materialNameSorting,
  getMaterialGridView: state => state.materialGridView,
  getMaterialCategory: state => state.materialCategory,
  getSuperAdminTab: state => state.superAdminTab,
}

const appSettingsModule = {
  state,
  mutations,
  actions,
  getters
}

export default appSettingsModule;