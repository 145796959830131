import axios from 'axios';



const state = {
  materials: [],
}

const mutations = {
  SET_MATERIALS (state, payload) {
    // console.log("SET_MATERIALS payload: ", payload);
    state.materials = payload
  },

  ADD_MATERIALS (state, payload) {
    // console.log("ADD_MATERIALS payload: ", payload);
    state.materials = state.materials.concat(payload);
  },

  DELETE_MATERIALS (state, payload) {
    // console.log("DELETE_MATERIALS payload: ", payload);
    state.materials = state.materials.filter(m => !payload.includes(m.id))
  },

  SET_MATERIAL_PREVIEW (state, payload) {
    // console.log("DELETE_MATERIALS payload: ", payload);
    var material = state.materials.find(m => m.id == payload.materialId)
    material.assemblyPreview = payload.assemblyPreview
  }
}

const actions = {
  async fetchMaterials (context, payload) {
    context.commit('SET_MATERIALS', []);
    let response = await axios.get(`/U3M/Materials/`)
    // console.log("Fetched materials..response ", response.data);
    context.commit('SET_MATERIALS', response.data);
  },


  async downloadMaterial(context, material){

    console.log("downloadMaterial id: ", material, " context ", context);

    if((material == null) || (material.downloadFile.length == 0))
        return false

    let basePath = context.getters.getActiveEnvironment.storagePath
    let container = context.getters.getStorageContainer.u3mdownloads
    //e.g. https://u3mteststore.blob.core.windows.net/u3mdownloads/CompanyId-55555/9e8fd6c7-0ee5-4922-a8f9-741803c883d2@Oxford_DEMO.zip`
    let path = `${basePath}${container}/${material.companyId}/${material.downloadFile}`

    let response = await axios.get(path, {responseType: 'blob'}) // responseType: 'blob', // important

    let fileName = ((material.name != null) && (material.name.length > 0))? material.name : `"U3M_Material_"${material.id}`

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.zip`); //or any other extension
    document.body.appendChild(link);
    link.click();
  },

  async uploadMaterials (context, formData) { //Upload Material from Store
    const headerOptions = {
      headers: {'Content-Type': 'multipart/form-data' }
    };

    // Post to -> https://polytecdntestserver.azurewebsites.net/U3M/Upload
    let response = await axios.post('/U3M/Upload', formData, headerOptions)
    context.commit('ADD_MATERIALS',  response.data);
  },

  async deletMaterials (context, materialIds) { // Delete material(s) Note: takes array for possibly multiple deletion
    // console.log("deletMaterials: ", materialIds);
    let response = await axios.delete(`/U3M/Materials/`,{ data: materialIds })
    context.commit('DELETE_MATERIALS', materialIds);
    return true;
    // console.log("deletMaterials materials..response ", response.data);
  },

  async updateMaterialPreview (context, updatePreviewObj) { //Update the materialpreview (replace the default png)
    // console.log("updateMaterialPreview ...", updatePreviewObj);
    let materialId = updatePreviewObj.materialId;
    let sendObj = { dataUrl: updatePreviewObj.imageData}
    // returns {assemblyPreview = previewFileName }
    let response = await axios.post(`/U3M/Materials/${materialId}/Preview`, sendObj) //note: return necessary here (async)!!
    context.commit('SET_MATERIAL_PREVIEW', { ...response.data, materialId });
    return true;
  },
}

const getters = {
  getMaterials: state => state.materials,
  getMaterialsById: (state, getters) => (materialId) => getters.getMaterials.find(m => m.id == materialId),
}


const materialModule = {
  state,
  mutations,
  actions,
  getters
}

export default materialModule
